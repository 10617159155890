/* eslint-disable @angular-eslint/component-class-suffix */
// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FetchData as utility } from 'app/shared/generalMethods';
@Component({
    selector: 'modal-waiting-program-warning',
    templateUrl: './modal-waiting-program-warning.component.html',
    styleUrls: ['./modal-waiting-program-warning.component.scss'],
})
export class ModalWaitingProgramWarningComponent  {
    recordId: any;
    text: any;
    notice: any;

    constructor(
        public dialogRef: MatDialogRef<ModalWaitingProgramWarningComponent >,
        private router: Router,
         private _utility: utility,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.text = data.record;
        this.notice =
            this._utility.data.lstAppSettings[0].Waitlist_Program_Notice__c;
    }



    onCancel(event): void {
        this.dialogRef.close(false);
    }

    onSelect(): void {
        this.dialogRef.close(true);
      }

}
