// DEV




export const environment = {
  production: false,
  name: 'Developer',
  baseUrl: 'https://dev.app.bgcwolfcreek.com/',
  apiUrl: 'https://us-central1-developer-wc-instance.cloudfunctions.net/api/parent',
  apiUrlNewCall: 'https://us-central1-developer-wc-instance.cloudfunctions.net/api/',
  apiUrlCache: 'https://us-central1-developer-wc-instance.cloudfunctions.net/api/cache',
  firebase: {
    apiKey: 'AIzaSyDm1dA4z5WA92v4zjQ52Ep63jWQE_-Io9M',
    authDomain: 'developer-wc-instance.firebaseapp.com',
    projectId: 'developer-wc-instance',
    storageBucket: 'developer-wc-instance.appspot.com',
    messagingSenderId: '1011877106712',
    appId: '1:1011877106712:web:b999ddf906ecd46b28df6d',
    measurementId: 'G-SV614N1C7W',
  },
  ipifyUrl: 'https://api.ipify.org/?format=json',
  ipinfoUrl: 'https://ipinfo.io/',
  ipinfoToken: '?token=abe7cb4c38b788',
  getClientIPAddressByIpify: 'getClientIPAddressByIpify',
};




// LOCAL



// export const environment = {
//     production: false,
//     name: 'Local',
//     baseUrl: 'http://localhost:4400/',
//     apiUrl: 'http://localhost:5001/developer-wc-instance/us-central1/api/parent',
//     apiUrlNewCall:
//         'http://localhost:5001/developer-wc-instance/us-central1/api/',
//     apiUrlCache:
//         'http://localhost:5001/developer-wc-instance/us-central1/api/cache',
//     apiUrlMail:
//         'http://localhost:5001/developer-wc-instance/us-central1/api/mail',
//     firebase: {
//         apiKey: 'AIzaSyBuWw3BCaMAFZcAi-mvcppPClLmSMGQR7c',
//         authDomain: 'uat-bgc-wolf-creek.firebaseapp.com',
//         projectId: 'uat-bgc-wolf-creek',
//         storageBucket: 'uat-bgc-wolf-creek.appspot.com',
//         messagingSenderId: '512821131535',
//         appId: '1:512821131535:web:4764e28d9f697b9393e67a',
//         measurementId: 'G-JXPXNLL3KN',
//     },
//     ipifyUrl: 'https://api.ipify.org/?format=json',
//     ipinfoUrl: 'https://ipinfo.io/',
//     ipinfoToken: '?token=abe7cb4c38b788',
//     getClientIPAddressByIpify: 'getClientIPAddressByIpify',
// };





//UAT



// export const environment = {
//     production: false,
//     name: 'UAT',
//     baseUrl: 'https://uat-bgc-wolf-creek.web.app/',
//     apiUrl: 'https://us-central1-uat-bgc-wolf-creek.cloudfunctions.net/api/parent',
//     apiUrlCache:
//         'https://us-central1-uat-bgc-wolf-creek.cloudfunctions.net/api/cache',
//     apiUrlNewCall:
//         'https://us-central1-uat-bgc-wolf-creek.cloudfunctions.net/api/',
//     firebase: {
//         apiKey: 'AIzaSyBuWw3BCaMAFZcAi-mvcppPClLmSMGQR7c',
//         authDomain: 'uat-bgc-wolf-creek.firebaseapp.com',
//         projectId: 'uat-bgc-wolf-creek',
//         storageBucket: 'uat-bgc-wolf-creek.appspot.com',
//         messagingSenderId: '512821131535',
//         appId: '1:512821131535:web:4764e28d9f697b9393e67a',
//         measurementId: 'G-JXPXNLL3KN',
//     },
//     ipifyUrl: 'https://api.ipify.org/?format=json',
//     ipinfoUrl: 'https://ipinfo.io/',
//     ipinfoToken: '?token=abe7cb4c38b788',
//     getClientIPAddressByIpify: 'getClientIPAddressByIpify',
// };



// PROD



// export const environment = {
//   production: true,
//   name: 'Production',
//   baseUrl: 'https://app.bgcwolfcreek.com/',
//   apiUrl: 'https://us-central1-member-bgcwolfcreek.cloudfunctions.net/api/parent',
//   apiUrlNewCall:
//       'https://us-central1-member-bgcwolfcreek.cloudfunctions.net/api/',
//   apiUrlCache:
//       'https://us-central1-member-bgcwolfcreek.cloudfunctions.net/api/cache',
//   firebase: {
//       apiKey: 'AIzaSyARdSSObDpfXE8ReokfVMudHb59ntmnA1o',
//       authDomain: 'member-bgcwolfcreek.firebaseapp.com',
//       projectId: 'member-bgcwolfcreek',
//       storageBucket: 'member-bgcwolfcreek.appspot.com',
//       messagingSenderId: '25588724574',
//       appId: '1:25588724574:web:b6b2e07e8ee2dbbdc355cf',
//       measurementId: 'G-SR90623K14',
//   },
//   ipifyUrl: 'https://api.ipify.org/?format=json',
//   ipinfoUrl: 'https://ipinfo.io/',
//   ipinfoToken: '?token=abe7cb4c38b788',
//   getClientIPAddressByIpify: 'getClientIPAddressByIpify',
// };
