/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {
    Component,
    Input,
    OnInit,
    SimpleChanges,
    EventEmitter,
    Output,
} from '@angular/core';
import { ClassScheduleService } from 'app/services/class-schedule.service';
import { isEmpty } from 'lodash';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-class-schedule',
    templateUrl: './class-schedule.component.html',
    styleUrls: ['./class-schedule.component.scss'],
})
export class ClassScheduleComponent implements OnInit {
    @Input() selectedProgramId: string;
    @Input() kidName: string;
    @Input() selectedKid: [];
    @Output() setClassScheduleListForProgramRegistration: EventEmitter<any[]> =
        new EventEmitter<any[]>();
    @Output() classScheduleListChanged: EventEmitter<any[]> = new EventEmitter<
        any[]
    >();
    isWaitingTrue: boolean;
    selectedClassSchedule: any;
    rows: any[];
    onFullyBooked: boolean;
    onWaitingList: boolean;
    waitingListMessage: string;
    selected = [];

    constructor(private _classScheduleService: ClassScheduleService) {}

    ngOnInit(): void {
        this.waitinglistMessage();
        this.showClassOnWaitingLegend();
        this.showClassOnFullyBookedLegend();
    }

    ngOnChanges(changes: SimpleChanges): any {
        this.isWaitingTrue = false;
        if (!isEmpty(this.selectedProgramId)) {
            this._classScheduleService.getProgramId(this.selectedProgramId);
        }
        if (!isEmpty(this.kidName)) {
            this._classScheduleService.getKidName(this.kidName);
        }
    }

    showClassOnWaitingLegend(): void {
        this._classScheduleService.isClassScheduleOnWaitingList.subscribe(
            (isOnWaitingList: boolean) => {
                this.onWaitingList = isOnWaitingList;
            }
        );
    }

    showClassOnFullyBookedLegend(): void {
        this._classScheduleService.isClassScheduleOnFullyBooked.subscribe(
            (onFullyBooked: boolean) => {
                this.onFullyBooked = onFullyBooked;
            }
        );
    }

    waitinglistMessage(): any {
        this.waitingListMessage =
            this._classScheduleService.fetchWaitingListMessage();
    }

}
