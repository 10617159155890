/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable space-before-function-paren */
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProgramRegistrationData } from 'app/interface/i-program-registration';
import { FetchData as utility } from 'app/shared/generalMethods';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { formatDate } from 'app/shared/generalMethods';

@Injectable({
    providedIn: 'root',
})
export class ProgramRegistrationService {
    private headers: HttpHeaders;
    private options = {};
    private user: any;
    private parentIdOrUid = '';
    private serverUrl = environment.apiUrlNewCall;
    private serverCache = environment.apiUrlCache;

    constructor(private http: HttpClient, private _utility: utility) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.options = { headers: this.headers };
        this.parentIdOrUid = this._utility.parentId;
    }

    userHasNoMemberShip(): any {
        return this._utility.data.lstKids;
    }

    userHasNoMemebrshipProfile(): any {
        return this._utility.data.lstMembershipProfiles.some(
            (profile) => profile > 0
        );
    }

    userHasNoMemberShipMessage(): any {
        const message = this._utility.data.lstAppSettings.find(
            (settings) => settings.No_Registration_Found__c
        );
        return message ? message.No_Registration_Found__c : undefined;
    }

    fetchFormSubmitMessage(): any {
        const message = this._utility.data.lstAppSettings.find(
            (settings) => settings.Loading_message__c
        );
        return message ? message.Loading_message__c : undefined;
    }

    fetchMessageListFromAppSettings(): any {
        const appSettings = this._utility.data?.lstAppSettings || [];
        const messageList = appSettings.find(
            (settings) => settings.Is_Waiting_List_Message__c
        );
        return messageList ? messageList.Is_Waiting_List_Message__c : undefined;
    }
    fetchProgramList(): any {
        return this._utility.data?.lstPrograms;
    }

    fetchKidList(programId): any {
        const community = this._utility.data?.lstLocations?.filter(function (
            item
        ) {
            return item.Id === programId;
        });

        const kidList = this._utility.data?.lstKids?.filter(function (item) {
            return item.Community_Registering_For__c === community[0]?.Name;
        });
        return kidList;
    }

    fetchClassScheduleByProgramID(programId): any {
        const lstClassSchedules = this._utility.data?.lstClassSchedules;
        const classSchedule = lstClassSchedules?.filter(function (item) {
            return item.Program__c === programId;
        });
        classSchedule?.forEach((element) => {
            element.startTime = formatDate(
                element.Date__c,
                element.Start_Time__c
            );
            element.endTime = formatDate(element.Date__c, element.End_Time__c);
        });
        return classSchedule;
    }

    fetchKidHasHealth(): any {
        return this._utility.data.lstHealthInformations;
    }

    fetchListRegistrationSchedules(): any {
        return this._utility.data.lstRegistrationSchedules;
    }

    fetchParentInformation(): any {
        const parent = this._utility.data.lstParents.find((parent) => true);
        return parent ? parent : undefined;
    }

    // Creating new program registration
    addProgramRegistration(
        programRegistrationObject: IProgramRegistrationData
    ): Observable<any> {
        const body = [];
        const url = this.serverUrl + 'program-registration/enroll';
        body.push(programRegistrationObject);
        return this.http
            .post(url, body, this.options)
            .pipe(catchError(this.handleError));
    }

    cancelProgramRegistration(data): Observable<any> {
        const body = [];
        const url = this.serverUrl + 'program-registration/enrollCancel';
        body.push(data);
        return this.http
            .post(url, body, this.options)
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // Return an observable with a user-facing error message
        return throwError('Something went wrong. Please try again later.');
    }
}
