<ng-container>
    <div>
        <h1 class="text-3xl text-center truncate dashboard-truncate">
            {{ getAccountStatusMessage() }}
        </h1>
        <div class="text-center mt-6" *ngIf="isShowProgramRegistration()">
            <button class="ml-3" mat-flat-button [routerLink]="'/program-registration'" [color]="'primary'">Sign up for new program</button>
          </div>
          
          <div class="shrink-0 items-center text-center mt-6 sm:mt-0 sm:ml-4 p-8" *ngIf="isShowAddNewMembership()">
            <button class="ml-3" mat-flat-button [routerLink]="'/membership'" [color]="'primary'">Add New Membership</button>
          </div>
          
          <div class="shrink-0 items-center text-center mt-6 sm:mt-0 sm:ml-4 p-8" *ngIf="isShowAddMembership()">
            <button class="ml-3" mat-flat-button [routerLink]="'/membership'" [color]="'primary'">Add Membership</button>
          </div>
    </div>
</ng-container>
