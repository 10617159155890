<div class="message-to-open-waiver">
    <span class="message-indication">Click on policy waiver to open and sign the waiver</span>
</div>
<mat-accordion class="headers-align" [expanded]="true" multi *ngFor="let data of programWaiver">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="policy-name" [matTooltip]="data.isMandatory ? 'This Policy Waiver Has Been Mandatory To Be Accepted' : ''">{{ data.Name }}</span>
                <span class="policy-last-updated">Last Updated: {{ data.LastModifiedDate | date : "dd/MM/yyyy" }}</span>
                <span *ngIf="data.isMandatory"
                [ngClass]="{ 'mandatory-flag': data.isMandatory }">This is Mandatory</span>
                <span *ngIf="!data.isMandatory"
                [ngClass]="{ 'mandatory-fla': !data.isMandatory }">This is Optional</span>
            </mat-panel-title>
            <div class="policy-action-icons">
                <mat-icon
                    [ngClass]="{ checked: data.isAccepted  }"
                    matTooltip="Policy Acceptance"
                    ><span class="material-icons">check_circle</span></mat-icon
                >
            </div>
            <div class="policy-action-icons">
                <mat-icon class="material-icons download" matTooltip="Download PDF" (click)="generatePDF(data)"
                    ><span>file_download</span></mat-icon
                >
            </div>
            <div class="policy-action-icons">
                <mat-icon *ngIf="data.isMandatory"
                    matTooltip="Policy Acceptance Is Mandatory"
                    [ngClass]="{ 'mandatory-flag': data.isMandatory }"
                >
                    <span class="material-icons">report</span>
                </mat-icon>
                <mat-icon *ngIf="!data.isMandatory"
                    matTooltip="Policy Acceptance Is Optional"
                    [ngClass]="{ 'mandatory-fla': !data.isMandatory }"
                >
                    <span class="material-icons">report</span>
                </mat-icon>
            </div>
        </mat-expansion-panel-header>
        <mat-panel-description>
            <span class="policy-last-updated-date">Last Updated: {{ data.LastModifiedDate | date : "dd/MM/yyyy" }}</span>
        </mat-panel-description>
        
        <mat-panel-description>
            <div class="policy-description" [innerHTML]="data.policyData"></div>
        </mat-panel-description>
        <mat-panel-description>
            <mat-checkbox 
                [(ngModel)]="policyAccepted"
                (click)="updatePolicyIsAccepted(data)"
            >
                I have read and agree to the above policies and procedures
            </mat-checkbox>
        </mat-panel-description>
    </mat-expansion-panel>
</mat-accordion>
