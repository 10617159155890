<div class="container">
  <div class="modalHeader">
    <h2 mat-dialog-title>Update Kids' Grades</h2>
    <p class="modalDescription">Please update the grades for each kid below. <br></p>
  </div>
  <p class="modalNote">Note: Already updated the grades? Click Cancel.</p>
  <form [formGroup]="kidsForm" (ngSubmit)="onFormSubmit()">
    <div class="kidsGrade" *ngFor="let kid of userRecord?.lstKids">
      <mat-form-field appearance="outline">
        <mat-label class="kidsLabel">Grade for {{ kid.Name }}</mat-label>
        <input
          matInput
          [formControlName]="kid.Id"
          placeholder="Enter grade"
        />
        <mat-error *ngIf="kidsForm.get(kid.Id)?.hasError('required')">
          Grade is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="actionBtn">
      <button mat-button type="button" (click)="dialogRef.close()">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="kidsForm.invalid || isDisabledButton"
        type="submit"
      >
        Update
      </button>
    </div>
  </form>
  
</div>


