import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FetchData } from 'app/shared/generalMethods';

@Component({
  selector: 'app-modal-payment-acknowledgment',
  templateUrl: './modal-payment-acknowledgment.component.html',
  styleUrls: ['./modal-payment-acknowledgment.component.scss']
})
export class ModalPaymentAcknowledgmentComponent implements OnInit {
  acknowledgeForm: FormGroup;
  userRecord: any;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ModalPaymentAcknowledgmentComponent>,
    private fb: FormBuilder,
    private _fetchData: FetchData,
    @Inject(MAT_DIALOG_DATA) public data: { totalDue: number }
  ) {}

  ngOnInit(): void {
    this.userRecord = this._fetchData.data;
    this.paymentAcknowledgmentMessage();
    this.acknowledgeForm = this.fb.group({
      acknowledged: [false],
      acknowledgedDate: [null],
      acknowledgedTime: [null]
    });

    this.acknowledgeForm.get('acknowledged')?.valueChanges.subscribe((isChecked) => {
      if (isChecked) {
        const now = new Date();
        this.acknowledgeForm.patchValue({
          acknowledgedDate: now.toLocaleDateString(),
          acknowledgedTime: now.toLocaleTimeString(),
        });
        this.dialogRef.close(this.acknowledgeForm.value);
      } else {
        this.acknowledgeForm.patchValue({
          acknowledgedDate: null,
          acknowledgedTime: null
        });
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close(this.acknowledgeForm.value);
  }

  paymentAcknowledgmentMessage(): void {
    this.message = this.userRecord.lstAppSettings[0]?.Payment_Acknowledge_Message__c;
}
}
