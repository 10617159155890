/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {
    Component,
    OnInit,
    SimpleChanges,
    Input,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { ClassScheduleService } from 'app/services/class-schedule.service';
import { ProgramRegistrationService } from 'app/services/program-registration.service';
import { ModalWaitingProgramWarningComponent  } from '../../modal/modal-waiting-program-warning/modal-waiting-program-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { ShowWaitlistProgramModal } from '../../modal/show-waitlist-program-modal/show-waitlist-program-modal.component';
import { ChangeDetectorRef } from '@angular/core';


@Component({
    selector: 'app-class-schedule-compact-view',
    templateUrl: './class-schedule-compact-view.component.html',
    styleUrls: ['./class-schedule-compact-view.component.scss'],
})
export class ClassScheduleCompactViewComponent implements OnInit {
    @Input() kidName: string;
    @Input() selectedProgramId: string;
    @Input() selectedKid: any[];
    @Input() isWaitingTrue: boolean;
    @Output() setClassScheduleListForProgramRegistration: EventEmitter<any[]> =
        new EventEmitter<any[]>();

    @ViewChild('modalTemplate') modalTemplate: any;

    onWaitingList: boolean;
    onFullyBooked: boolean;
    rows: any[];
    waitButtonToggle = true;
    allProgramList: any[];
    selectClassScheduleList: any;
    waitingListMessage: string;
    isKidSubsidize: boolean;
    showLegend: boolean;
    isWaitingClassSchedule: boolean;
    selectedCount = 0;

    constructor(
        public classScheduleService: ClassScheduleService,
        private _programRegistrationService: ProgramRegistrationService,
        public dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.isWaitingClassSchedule = false;
    }

    ngOnChanges(changes: SimpleChanges): any {
        this.selectClassScheduleList = [];
        this.isWaitingTrue = false;
        this.allProgramList =
            this.classScheduleService.getClassScheduleFromClassScheduleService();
        this.isWaitingClassSchedule = this.classScheduleService.onWaitingList;
        this.rows = this.allProgramList;
        if (changes.selectedKid) {
            if (changes.selectedKid.currentValue.Id) {
                this.isKidSubsidize =
                    changes.selectedKid.currentValue.Is_Subsidies__c;
            }
        }
        if (this.rows) {
            this.filterWaitingListProgram();
        }
    }

    showWaitlistProgramConfirmation(): void {
        const dialogRef = this.dialog.open(ShowWaitlistProgramModal, {
            width: '400px',
            data: {
                title: 'Program is in waiting list',
                message:
                    'Are you sure you want to view the waiting list program?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showWaitListProgram();
            }
        });
    }

    showWaitListProgram(): any {

        // const progId = this.rows[0].Program__c;
        const progId = this.selectedProgramId;
        this.selectClassScheduleList = [];
        this.isWaitingTrue = false;
        this.classScheduleService.getAllClassByProgramId(progId);
        this.allProgramList =
            this.classScheduleService.getClassScheduleFromClassScheduleService();
        this.rows = this.allProgramList;

        this._programRegistrationService
            .fetchListRegistrationSchedules()
            .forEach((registrationSchedule) => {
                this.rows.forEach((classSchedule, index) => {
                    if (
                        registrationSchedule.Class_Schedule__c ===
                            classSchedule.Id &&
                        registrationSchedule.Kid_name__c === this.kidName
                    ) {
                        this.rows.splice(index, 1);
                        this.rows = [...this.rows];
                    } else {
                        this.rows = [...this.rows];
                    }
                });
            });
        this.waitButtonToggle = false;
    }

    filterWaitingListProgram(): any {
        this.waitButtonToggle = true;
        const indicesToRemove: number[] = [];
        this.rows.forEach((element, index) => {
            if (
                element.Is_Active__c === true &&
                element.Available_Spaces__c === element.Booked_Count__c &&
                element.Available_Waiting_Spaces__c > element.Booked_Waiting__c
            ) {
                indicesToRemove.push(index);
            }
        });

        for (let i = indicesToRemove.length - 1; i >= 0; i--) {
            this.rows.splice(indicesToRemove[i], 1);
        }
        this.rows = [...this.rows];
        this.selectClassScheduleList = [];
        this.classScheduleService.setWaitingMessageCheckOnSelectClassSchedule(this.isWaitingTrue = false);
        this.classScheduleService.setSelectClassScheduleListForProgramRegistration(this.selectClassScheduleList);
    }

    isClassOnWaitingList(row: any): boolean {
        return (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c > row.Booked_Waiting__c
        );
    }

    isClassFullyBooked(row: any): boolean {
        return (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c === row.Booked_Waiting__c
        );
    }

    removeClassScheduleAfterRegistration(kidName): void {

        this._programRegistrationService
            .fetchListRegistrationSchedules()
            .forEach((registrationSchedule) => {
                this.rows.forEach((classSchedule, index) => {
                    if (
                        registrationSchedule.Class_Schedule__c ===
                            classSchedule.Id &&
                        registrationSchedule.Kid_name__c === kidName
                    ) {
                        this.rows.splice(index, 1);
                        this.rows = [...this.rows];
                    } else {
                        this.rows = [...this.rows];
                    }
                });
            });
        this.checkClassAvailableSpaces(this.rows);
    }

    getRowClass(row): any {
        if (row.Is_Active__c === false) {
            return 'unavailable';
        } else if (
            row.Is_Active__c === true &&
            row.Available_Spaces__c > row.Booked_Count__c
        ) {
            return 'available';
        } else if (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c > row.Booked_Waiting__c
        ) {
            return 'waitlist';
        } else if (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c === row.Booked_Waiting__c
        ) {
            return 'allbooked';
        }
    }

    checkClassAvailableSpaces(obj): any {
        this.onFullyBooked = false;
        this.onWaitingList = false;
        obj.forEach((element) => {
            if (
                element.Available_Spaces__c === element.Booked_Count__c &&
                element.Available_Waiting_Spaces__c > element.Booked_Waiting__c
            ) {
                this.onWaitingList = true;
                return true;
            }
            if (
                element.Available_Spaces__c === element.Booked_Count__c &&
                element.Available_Waiting_Spaces__c ===
                    element.Booked_Waiting__c
            ) {
                this.onFullyBooked = true;
                return true;
            } else {
                return false;
            }
        });
    }

    isUnavailable(row: any): boolean {
        return !row.Is_Active__c;
    }

    isAvailable(row: any): boolean {
        return (
            row.Is_Active__c && row.Available_Spaces__c > row.Booked_Count__c
        );
    }

    isWaitlist(row: any): boolean {
        return (
            row.Is_Active__c &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c > row.Booked_Waiting__c
        );
    }

    isAllBooked(row: any): boolean {
        return (
            row.Is_Active__c &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c === row.Booked_Waiting__c
        );
    }


    onSelect(event: any): void {
        const selected = event.selected[event.selected.length - 1];

        if (selected && this.selectedCount <= event.selected.length) {
            if (
                selected.Is_Active__c === true &&
                selected.Available_Spaces__c === selected.Booked_Count__c &&
                selected.Available_Waiting_Spaces__c >
                    selected.Booked_Waiting__c
            ) {
                this.openWarningDialogForWaitList(selected);
            } else {
            }
        }
        this.selectedCount = event.selected.length;
    }

    openWarningDialogForWaitList(selected: any) {
        const dialogRef = this.dialog.open(
            ModalWaitingProgramWarningComponent,
            {
                height: 'auto',
                width: 'auto',
                maxWidth: '750px',
                data: {
                    record: 'e',
                },
            }
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                const indexToRemove =
                    this.selectClassScheduleList.indexOf(selected);
                if (indexToRemove > -1) {
                    this.selectClassScheduleList.splice(indexToRemove, 1);
                }
                this.selectClassScheduleList = [
                    ...this.selectClassScheduleList,
                ];
                this.changeDetectorRef.detectChanges();
            }
        });
    }
}
