<h1 mat-dialog-title class="text-center text-3xl mb-3 modalHeading">Attention</h1>

<div mat-dialog-content>

    <div class="text-center">
        <h1 class="modalMessage" [innerHTML]="attentionMessage"></h1>
    </div>

</div>

<div class="text-center mt-8">

    <button mat-flat-button=""
        class="mat-focus-indicator ml-3 mat-flat-button mat-button-base mat-warn ng-star-inserted"
        ng-reflect-color="warn" (click)="onSelect()">
        <span class="mat-button-wrapper">Ok</span>
        <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false"
            ng-reflect-trigger="[object HTMLButtonElement]"></span>
        <span class="mat-button-focus-overlay"></span>
    </button>
</div>