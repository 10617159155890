/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:view-grid',
        link : '/dashboard',
    },
    {
        id   : 'membership',
        title: 'Membership',
        type : 'basic',
        icon : 'heroicons_outline:ticket',
        link : '/all-membership'
    },
    {
        id   : 'registered-programs',
        title: 'Programs',
        type : 'basic',
        icon : 'heroicons_outline:calendar',
        link : '/registered-programs'
    },
    {
        id   : 'contactus',
        title: 'Contact Us',
        type : 'basic',
        icon : 'heroicons_outline:inbox',
        link : '/contact-us'
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:view-grid',
        link : '/dashboard'
    },
    {
        id   : 'membership',
        title: 'Membership',
        type : 'basic',
        icon : 'heroicons_outline:ticket',
        link : '/all-membership'
    },
    {
        id   : 'registered-programs',
        title: 'Programs',
        type : 'basic',
        icon : 'heroicons_outline:calendar',
        link : '/registered-programs'
    },
    {
        id   : 'contactus',
        title: 'Contact Us',
        type : 'basic',
        icon : 'heroicons_outline:inbox',
        link : '/contact-us'
    },
];

export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:view-grid',
        link : '/dashboard'
    },
    {
        id   : 'membership',
        title: 'Membership',
        type : 'basic',
        icon : 'heroicons_outline:ticket',
        link : '/all-membership'
    },
    {
        id   : 'registered-programs',
        title: 'Programs',
        type : 'basic',
        icon : 'heroicons_outline:calendar',
        link : '/registered-programs'
    },
    {
        id   : 'contactus',
        title: 'Contact Us',
        type : 'basic',
        icon : 'heroicons_outline:inbox',
        link : '/contact-us'
    },
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:view-grid',
        link : '/dashboard'
    },
    {
        id   : 'membership',
        title: 'Membership',
        type : 'basic',
        icon : 'heroicons_outline:ticket',
        link : '/all-membership'
    },
    {
        id   : 'registered-programs',
        title: 'Programs',
        type : 'basic',
        icon : 'heroicons_outline:calendar',
        link : '/registered-programs'
    },
    {
        id   : 'contactus',
        title: 'Contact Us',
        type : 'basic',
        icon : 'heroicons_outline:calendar',
        link : '/contact-us'
    },
];
