/* eslint-disable max-len */
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import jsPDF from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { htmlToText } from 'html-to-text';
import { imgHeaderData, youthCenterImg } from 'app/shared/generalMethods';

const htmlToPdfMake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-policy-waiver',
    templateUrl: './policy-waiver.component.html',
    styleUrls: ['./policy-waiver.component.scss'],
})
export class PolicyWaiverComponent implements OnInit, OnChanges {
    @Output() mandatoryCheckEvent = new EventEmitter<boolean>();
    @Output() mandatoryCheckArrayEvent = new EventEmitter<any>();

    @Input() programWaiver = [];
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild('pdfTable') pdfTable!: ElementRef;
    checked = false;
    policyAccepted: boolean = false;
    isMandatory: boolean = false;
    isMandatoryChecked = false;
    totalMandatoryCount = 0;
    mandatoryCheckCount = 0;
    pdfObj: jsPDF = new jsPDF();

    constructor() {}

    ngOnInit(): void {
        this.setDefaultValues();
        this.getMandatoryCheckForValidation();
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    ngOnChanges(element: SimpleChanges) {
        this.ngOnInit();
    }

    setDefaultValues(): void {
        this.totalMandatoryCount = 0;
        this.mandatoryCheckCount = 0;
        this.isMandatory = false;
    }

    generatePDF(event): void {
        this.pdfObj = new jsPDF();
        const policyContent = htmlToText(event.Policies__c);
        const pdfWidth = this.pdfObj.internal.pageSize.getWidth();
        const pdfHeight = this.pdfObj.internal.pageSize.getHeight();
        const imgHeight = pdfHeight * 0.2;
        const centerX = pdfWidth / 2;
        const imgHeaderXValue = centerX - 78;

        this.setPdfHeaderImages(this.pdfObj, imgHeaderXValue);
        this.setPdfTitles(this.pdfObj, event.Name);
        this.setPdfProgramWaiverContent(this.pdfObj, policyContent, imgHeight);
        this.setPdfFooterContent(this.pdfObj, centerX, pdfHeight, pdfWidth);
        this.pdfObj.save(event.Name);
    }

    setPdfTitles(pdfObj, policyWaiverTitle): void {
        pdfObj.setFontSize(14);
        pdfObj.setTextColor(68, 70, 84);
        pdfObj.text('Program Waiver', 85, 35);
        pdfObj.setFontSize(24);
        pdfObj.text(policyWaiverTitle, 26, 50);
    }

    setPdfHeaderImages(pdfObj, imgHeaderXValue): void {
        const imgHeaderDataImg = imgHeaderData;
        const imgYouthCenter = youthCenterImg;
        pdfObj.addImage(imgHeaderDataImg, 'PNG', imgHeaderXValue, 10, 75, 17);
        pdfObj.addImage(imgYouthCenter, 'PNG', 150, 8, 38, 26);
    }

    setPdfFooterContent(pdfObj, centerX, pdfHeight, pdfWidth): void {
        const addressFooterXValue = centerX - 45;
        const addressFooterYValue = pdfHeight - 30;
        const contactFooterXValue = centerX - 60;
        const contactFooterYValue = pdfHeight - 23;

        pdfObj.line(20, 52, pdfWidth - 20, 52);
        pdfObj.setFontSize(10);
        pdfObj.text(
            '#4 5004 54 St. |  Box 4115 | Ponoka, AB | T4J 1R5',
            addressFooterXValue,
            addressFooterYValue
        );
        pdfObj.setFontSize(10);
        pdfObj.text(
            'Tel: 403-783-3112 | Fax:403-783-3108 | Email: office@bgcwolfcreek.com',
            contactFooterXValue,
            contactFooterYValue
        );
    }

    setPdfProgramWaiverContent(pdfObj, policyContent, imgHeight): void {
        pdfObj.setFontSize(12);
        const contentWidth = 500;
        const contentLines = pdfObj.splitTextToSize(
            policyContent,
            contentWidth
        );
        const contentX = 26;
        const contentY = imgHeight;
        pdfObj.text(contentLines, contentX, contentY);
    }

    updatePolicyIsAccepted(policy): void {
        this.programWaiver.forEach((element) => {
            if (element.Id === policy.Id) {
                element.isAccepted = element.isAccepted ? false : true;
                if (element.isMandatory) {
                    this.mandatoryCheckCount += element.isAccepted ? 1 : -1;
                    this.isMandatory =
                        this.mandatoryCheckCount === this.totalMandatoryCount
                            ? true
                            : false;
                }
            }
        });
        this.setEventDataForProgramRegistration();
    }

    getMandatoryCheckForValidation(): any {
        this.programWaiver.forEach((element) => {
            if (element.isMandatory === true) {
                this.totalMandatoryCount += 1;
            }
        });
        if (this.totalMandatoryCount === 0) {
            this.isMandatory = true;
            this.setEventDataForProgramRegistration();
        }
    }

    setEventDataForProgramRegistration(): void {
        this.mandatoryCheckEvent.emit(this.isMandatory);
        this.mandatoryCheckArrayEvent.emit(this.programWaiver);
    }

    getIsMandatoryCheck(): boolean {
        return this.isMandatory;
    }
}
