/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FetchData } from 'app/shared/generalMethods';
import jsPDF from 'jspdf';
import { imgHeaderData, youthCenterImg } from 'app/shared/generalMethods';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

interface RegistrationSchedule {
    totalDue: number;
    programFee: number;
    programDescription: string;
    kidsName: string;
    notes: string;
    attendanceStatus: string;
    feesPaid: number;
    kidsId: string;
    createdDate: string;
    classSchedule: string;
    programDate: string;
}

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    providers: [DatePipe],
})
export class HistoryComponent implements OnInit, AfterViewInit {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild('historyTable') historyTable!: ElementRef;

    historyForm: FormGroup;
    displayedColumns: string[] = [
        'programDescription',
        'kidsName',
        'attendanceStatus',
        'programFee',
        'totalDue',
        'notes'
    ];
    scheduleData: RegistrationSchedule[] = [];
    filteredScheduleData: RegistrationSchedule[] = [];
    parentId: string;
    kids: any[] = [];
    classScheduleMap: Record<string, string> = {};
    selectedKid: string = 'all';
    selectedStatus: string = 'all';
    pdfObj: jsPDF = new jsPDF();
    kidsId: any[] = [];
    kidsName: any[] = [];
    physicalAddress: string = '';
    postalCode: string = '';
    townCity: string = '';
    parentName: string = '';

    constructor(
        private fb: FormBuilder,
        private fetchData: FetchData,
        private datePipe: DatePipe,
        private _snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.initHistoryForm();
        this.parentId = this.fetchData.parentId;
        this.filterKidsByParentId();
        this.loadSchedules();
        this.loadParentData();
        this.setInitialDateRange();
        // this.applyFilters();
    }

    onPanelOpened(): void {
        this.applyFilters();
    }

    private setInitialDateRange(): void {
        let filteredData = this.scheduleData;
        const today = new Date();
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);

        const formattedToday = this.datePipe.transform(today, 'yyyy-MM-dd');
        const formattedOneWeekAgo = this.datePipe.transform(
            oneWeekAgo,
            'yyyy-MM-dd'
        );

        // Update the form with default values
        this.historyForm.patchValue({
            fromDate: formattedOneWeekAgo,
            toDate: formattedToday,
        });

        if (this.selectedKid !== 'all') {
            filteredData = filteredData.filter(
                schedule => schedule.kidsId === this.selectedKid
            );
        }
    }

    ngAfterViewInit(): void {
        if (!this.historyTable) {
            console.error('historyTable element reference not initialized');
        }
    }

    private initHistoryForm(): void {
        this.historyForm = this.fb.group({
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
        });
    }

    private filterKidsByParentId(): void {
        const lstKids = this.fetchData.data?.lstKids || [];
        this.kids = lstKids.filter(kid => kid.Parent__c === this.parentId);
        this.kidsId = this.kids.map(kid => kid.Id);
        this.kidsName = this.kids.map(kid => kid.Kid_name__c);
    }

    private loadParentData(): void {
        const lstParent = this.fetchData.data?.lstParents || [];
        if (lstParent.length > 0) {
            const parent = lstParent[0];
            this.physicalAddress = parent.Physical_Address__c || 'N/A';
            this.postalCode = parent.Postal_Code__c || 'N/A';
            this.townCity = parent.Town_City__c || 'N/A';
            this.parentName = parent.Name || 'N/A';
        } else {
            console.warn('No parent data found in lstParent.');
        }
    }

    private loadSchedules(): void {
        const lstRegistrationSchedules =
            this.fetchData.data?.lstRegistrationSchedules || [];
        const lstAttendance = this.fetchData.data?.lstAttendance || [];
        this.scheduleData = lstRegistrationSchedules.map(schedule => ({
            programDescription:
                schedule.Class_Schedule__r?.Name ,
            attendanceStatus: schedule.Status__c,
            feesPaid: schedule.Paid_Fee__c ?? 0,
            programFee: schedule.Program_Fees__c ?? 0,
            totalDue: schedule.Total_Due__c,
            kidsId: schedule.Kids__c,
            kidsName: schedule.Kid_name__c,
            notes: schedule.Notes__c,
            createdDate: schedule.CreatedDate,
            classSchedule: schedule.Class_Schedule__c,
            programDate: schedule.Program_Date__c,
        }));
        this.processAttendance(lstAttendance, lstRegistrationSchedules);
        this.filteredScheduleData = [...this.scheduleData];
    }

    applyFilters(): void {
        const fromDate = this.historyForm.get('fromDate')?.value;
        const toDate = this.historyForm.get('toDate')?.value;

        let filteredData = this.scheduleData;

        if (this.selectedKid !== 'all') {
            filteredData = filteredData.filter(
                schedule => schedule.kidsId === this.selectedKid
            );
        }

        this.onKidSelectionChange();

        filteredData = filteredData.filter((schedule) => {
            const match =
                schedule.programDescription.match(/\d{4}-\d{2}-\d{2}/);
            if (!match) {
                return false;
            }

            const programDate = new Date(match[0]);

            const normalizedProgramDate = new Date(
                programDate.setHours(0, 0, 0, 0)
            );
            const normalizedFromDate = fromDate
                ? new Date(new Date(fromDate).setHours(0, 0, 0, 0))
                : null;
            const normalizedToDate = toDate
                ? new Date(new Date(toDate).setHours(0, 0, 0, 0))
                : null;

            if (
                normalizedFromDate &&
                normalizedFromDate > normalizedProgramDate
            ) {
                return false;
            }
            if (normalizedToDate && normalizedToDate < normalizedProgramDate) {
                return false;
            }
            return true;
        });

        if (this.selectedStatus !== 'all') {
          filteredData = filteredData.filter(
              schedule => schedule.attendanceStatus === this.selectedStatus
          );
      }

        this.filteredScheduleData = filteredData;
        if (this.filteredScheduleData.length === 0) {
            this.openSnackBar('No Data Found');
        }
    }

    resetFilters(): void {
        this.historyForm.reset();
        this.selectedKid = 'all';
        this.selectedStatus = 'all';
        this.filteredScheduleData = this.scheduleData;
    }

    onKidSelectionChange(): void {
        if (this.selectedKid === 'all') {
            this.filteredScheduleData = [...this.scheduleData];
        } else {
            this.filteredScheduleData = this.scheduleData.filter(
                schedule => schedule.kidsId === this.selectedKid
            );
        }
        if (this.filteredScheduleData.length === 0) {
            console.warn(
                `No data available for the selected kid with ID: ${this.selectedKid}`
            );
        }
    }

    getSelectedKidName(): string {
        const kid = this.kids.find(k => k.Id === this.selectedKid);
        return kid ? kid.Name : 'All Kids';
    }

    generatePDF(): void {
        this.pdfObj = new jsPDF();
        const pdfWidth = this.pdfObj.internal.pageSize.getWidth();
        const pdfHeight = this.pdfObj.internal.pageSize.getHeight();
        const centerX = pdfWidth / 2;
        const imgHeaderXValue = centerX - 78;
        const parentDetails = [
            { label: 'Parent Name:', value: this.parentName },
            { label: 'Physical Address:', value: this.physicalAddress },
            { label: 'Postal Code:', value: this.postalCode },
            { label: 'Town/City:', value: this.townCity },
        ];
        const parentTableHeaders = ['Parent Information', ''];
        const parentTableData = parentDetails.map(detail => [detail.label, detail.value]);

        autoTable(this.pdfObj, {
            theme: 'striped',
            head: [parentTableHeaders],
            body: parentTableData,
            startY: 60,
            margin: { left: 20, right: 20 },
            headStyles: { fillColor: [68, 70, 84] },
            bodyStyles: { fontSize: 12 },
        });
        this.setPdfHeaderImages(this.pdfObj, imgHeaderXValue);
        this.pdfObj.setFontSize(16);
        this.pdfObj.setTextColor(68, 70, 84);
        this.pdfObj.text('Activity Report', 85, 45);
        this.pdfObj.line(20, 48, pdfWidth - 20, 48);
        this.pdfObj.text('Registered Programs', 20, 115);

        const tableHeaders = [
            { header: 'Program', dataKey: 'programDescription' },
            { header: 'Kid', dataKey: 'kidsName' },
            { header: 'Status', dataKey: 'attendanceStatus' },
            { header: 'Fee', dataKey: 'programFee' },
            { header: 'Total Due', dataKey: 'totalDue' },
            { header: 'Notes', dataKey: 'notes' },
        ];
        const tableData = this.filteredScheduleData.map(row => ({
            programDescription: row.programDescription,
            kidsName: row.kidsName,
            attendanceStatus: row.attendanceStatus,
            programFee: `$${row.programFee.toFixed(2)}`,
            totalDue: `$${row.totalDue.toFixed(2)}`,
            notes: row.notes
        }));

        autoTable(this.pdfObj, {
            theme: 'striped',
            head: [tableHeaders.map(header => header.header)],
            body: tableData.map(row => [
                row.programDescription,
                row.kidsName,
                row.attendanceStatus,
                row.programFee,
                row.totalDue,
                row.notes
            ]),
            startY: 120,
            margin: { left: 20, right: 20 },
            headStyles: { fillColor: [68, 70, 84] },
        });
        this.setPdfFooterContent(this.pdfObj, centerX, pdfHeight, pdfWidth);
        this.pdfObj.save('Activity_Report.pdf');
    }


    setPdfHeaderImages(pdfObj, imgHeaderXValue): void {
        const imgHeaderDataImg = imgHeaderData;
        const imgYouthCenter = youthCenterImg;
        pdfObj.addImage(imgHeaderDataImg, 'PNG', imgHeaderXValue, 10, 75, 17);
        pdfObj.addImage(imgYouthCenter, 'PNG', 150, 8, 38, 26);
    }

    setPdfFooterContent(pdfObj, centerX, pdfHeight, pdfWidth): void {
        const addressFooterXValue = centerX - 45;
        const addressFooterYValue = pdfHeight - 23;
        const contactFooterXValue = centerX - 60;
        const contactFooterYValue = pdfHeight - 18;
        pdfObj.setFontSize(10);
        pdfObj.text(
            '#4 5004 54 St. |  Box 4115 | Ponoka, AB | T4J 1R5',
            addressFooterXValue,
            addressFooterYValue
        );
        pdfObj.setFontSize(10);
        pdfObj.text(
            'Tel: 403-783-3112 | Fax:403-783-3108 | Email: office@bgcwolfcreek.com',
            contactFooterXValue,
            contactFooterYValue
        );
    }

    setPdfActivityReportContent(pdfObj, historyTableContent, imgHeight): void {
        pdfObj.setFontSize(20);
        const contentWidth = 500;
        const contentLines = pdfObj.splitTextToSize(
            historyTableContent,
            contentWidth
        );
        const contentX = 26;
        const contentY = imgHeight;
        pdfObj.text(contentLines, contentX, contentY);
    }

    private processAttendance(
        lstAttendance: any[],
        classSchedules: any[]
    ): void {
        this.scheduleData.forEach((schedule) => {
            const attendance = lstAttendance.find(
                att =>
                    att.Class_Schedule__c === schedule.classSchedule &&
                    att.Program_Date__c === schedule.programDate &&
                    att.Kid__c === schedule.kidsId
            );

            if (attendance) {
                if (attendance.Is_Absent__c) {
                    schedule.attendanceStatus = 'Absent';
                } else if (attendance.In_Time__c || attendance.Out_Time__c) {
                    schedule.attendanceStatus = 'Present';
                } else {
                    schedule.attendanceStatus = 'Absent';
                }
            } else {
                schedule.attendanceStatus = 'Absent';
            }
        });

        this.filteredScheduleData = [...this.scheduleData];
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    openSnackBar(message: string) {
        this._snackBar.open(message, 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

}
