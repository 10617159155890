import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-instruction-guide',
  templateUrl: './instruction-guide.component.html',
  styleUrls: ['./instruction-guide.component.scss']
})
export class InstructionGuideComponent implements OnInit {
  @Input() dashboardInstructionGuide = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor() { }

  ngOnInit(): void {
  }
  
}
