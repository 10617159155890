import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetSalesforceDataService } from 'app/services/salesforce-data';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  welcomeMessage: any;

  constructor(
    private _getSalesforceDataService: GetSalesforceDataService,
    private _router: Router,
  ) { 
    this.getDataFromSalesforce();
  }

  ngOnInit(): void {
  }

  getDataFromSalesforce(): void {
    this._getSalesforceDataService.getDataFromSalesforce().subscribe(
      (response) => {
        if (response.success === true) {
          try {
            const dataSet = JSON.parse(response.msg);
            if (dataSet && dataSet.data && dataSet.data.lstAppSettings && dataSet.data.lstAppSettings.length > 0) {
              const killSwitch = dataSet.data.lstAppSettings[0].Kill_Switch__c;
              localStorage.setItem('public-schedule', response.msg);
  
              if (killSwitch === false) {
                this._router.navigate(['/sign-in']);
              } else {
                this._getSalesforceDataService.redirectUserToMaintenancePage();
              }
            } else {
            }
          } catch (error) {
            console.error("Error parsing JSON response:", error);
          }
        } else {
        }
      },
      (err) => {
      }
    );
  }  
}
