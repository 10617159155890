<ng-container>
  <div class="maintenance-div">
    <div class="logo">
      <img src="assets/images/logo/brandLogo.png">
    </div>
    <div class="content">
      <h1 class="heading">Sorry for the inconvenience.</h1>
      <h1 class="heading">Our portal is being updated. Please try again later.</h1>
    </div>
    <div class="contact">
      <span class="contacting-message">If you have questions or concerns, <br> <a class="query" [routerLink]="'/contactus'">Contact Us</a></span>
    </div>
  </div>
</ng-container> 