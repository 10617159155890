/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable space-before-function-paren */
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FetchData as utility } from 'app/shared/generalMethods';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class GetSalesforceDataService {
    private headers: HttpHeaders;
    private options = {};
    private serverUrl = environment.apiUrlNewCall;

    constructor(private http: HttpClient, private _utility: utility,  private routers: Router) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.options = { headers: this.headers };
    }

    getDataFromSalesforce(): Observable<any>{
        const url = this.serverUrl + 'get-salesforce-data/parentdata';
        return this.http.get(url, this.options)
        .pipe(catchError(this.handleError));
    }


    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // Return an observable with a user-facing error message
        return throwError('Something went wrong. Please try again later.');
    }

    redirectUserToMaintenancePage(): void{
        this.routers.navigate(['/maintenance']);
    }
}
