/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.scss'],
})
export class LegendsComponent implements OnInit {
  @Input() OnwaitingList = false;
  @Input() fullyBooked = false;

  constructor() {}

  ngOnInit(): void {}
}
