import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FetchData } from 'app/shared/generalMethods';
import { ParentPortalService } from 'app/services/parentPortal.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { GetSalesforceDataService } from 'app/services/salesforce-data';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig } from '@angular/material/snack-bar';


@Component({
  selector: 'app-modal-for-community-selection',
  templateUrl: './modal-for-community-selection.component.html',
  styleUrls: ['./modal-for-community-selection.component.scss']
})
export class ModalForCommunitySelectionComponent implements OnInit {
  @ViewChild('addMembershipNgForm') addMembershipNgForm: NgForm;
  userRecord: any;
  communityMessage = '';
  parentId: any;
  profileForm: FormGroup;
  apiResult: any;
  pageLoaderText: any;
  isDisabledButton: boolean = false;



  constructor(
    private _fetchData: FetchData,
    private _parentPortalService: ParentPortalService,
    public dialogRef: MatDialogRef<ModalForCommunitySelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _getSalesforceDataService: GetSalesforceDataService,
    private _snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.userRecord = this._fetchData.data;
        this.getParentCommunityMessage();
        this.getFirstKidCommunity();

    }

    getParentCommunityMessage(): void {
        this.communityMessage = this._fetchData.data.lstAppSettings[0].Parent_Community_Message__c;
    }

    getFirstKidCommunity(): void {

    let oldestKid = null;
        if (this._fetchData.data && this._fetchData.data.lstKids && this._fetchData.data.lstKids.length > 0) {
            oldestKid = this._fetchData.data.lstKids[0];

            for (let i = 1; i < this._fetchData.data.lstKids.length; i++) {
                const currentKid = this._fetchData.data.lstKids[i];
                if (new Date(currentKid.CreatedDate) < new Date(oldestKid.CreatedDate)) {
                    oldestKid = currentKid;
                }
            }
        }

        this.setFormValues(oldestKid);
    }


    setFormValues(response): void {
        this.parentId = response.Parent__c;
        this.profileForm = this._formBuilder.group({
            community: [
                response.Community_Registering_For__c !== 'null'
                    ? response.Community_Registering_For__c : '',
                [Validators.required],
            ]
        });
    }

    onFormSubmit(): void {
    const formData = this.profileForm;
    this.isDisabledButton = true;
        if (formData.valid) {
            this._parentPortalService
                .updateParentCommunity(this.profileForm.value, this.parentId)
                .subscribe(
                    (response) => {

                        this.dialogRef.close();
                        this.apiResult = this._fetchData.parseIntoJSON(
                            response.msg
                        );
                        if(this.apiResult.data === null){
                            this.redirectUserToMaintenancePage();
                        }
                        else {
                        if (this.apiResult.success === true) {
                            this._fetchData.setLocalStorage(response.msg);
                            this._snackBar.open('Record updated', 'Close', {
                                duration: 3000,
                                verticalPosition: 'top'
                              });
                            }
                        }
                    },
                    (err) => {
                        this.isDisabledButton = false;
                    }
                    //show error message
                );
        } else {
        }

    }

    redirectUserToMaintenancePage(): void{
        this._getSalesforceDataService.redirectUserToMaintenancePage();
    }

}

