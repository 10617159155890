<ng-container>
    <mat-accordion class="instruction-guide-accordion" classmulti *ngIf="hasValidExternalAccount">
        <mat-expansion-panel class="instruction-dashboard">
            <mat-expansion-panel-header class="schedule-header">
                <mat-panel-title>
                    <div class="accordion-heading">Upcoming Schedule</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0"
                    >

                    <div class="sm:col-span-2 md:col-span-4  flex flex-col flex-auto bg-card rounded-2xl overflow-hidden"
                        >
                        <div class="mt-3 sm:mt-0 sm:ml-2 schedule-tab-button" >
                            <mat-button-toggle-group
                            *ngIf="showTodayToggle"
                            value="today" #scheduleDaySelector="matButtonToggleGroup">
                            <mat-button-toggle value="today" (click)="todayData()">Today</mat-button-toggle>
                          </mat-button-toggle-group>
                          
                          <mat-button-toggle-group
                            *ngIf="showTomorrowToggle"
                            value="tomorrow" #scheduleDaySelector="matButtonToggleGroup">
                            <mat-button-toggle value="tomorrow" (click)="tomorrowData()">Upcoming</mat-button-toggle>
                          </mat-button-toggle-group>
                          
                          <mat-button-toggle-group
                            *ngIf="showTodayUpcomingToggles"
                            value="today" #scheduleDaySelector="matButtonToggleGroup">
                            <mat-button-toggle value="today" (click)="todayData()">Today</mat-button-toggle>
                            <mat-button-toggle value="tomorrow" (click)="tomorrowData()">Upcoming</mat-button-toggle>
                          </mat-button-toggle-group>
                          
                        </div>
                        <div class="flex flex-col mt-2 divide-y">
                            <ng-container
                                *ngFor="let scheduleItem of (showToday ? classScheduleId : registrationScheduleComingSoon)">
                                <div class="flex flex-row items-center justify-between py-4 px-0.5">
                                    <div class="flex flex-col">
                                        <div class="font-medium">{{scheduleItem.Name}}
                                        </div>
                                        <div
                                            class="flex flex-col sm:flex-row sm:items-center -ml-0.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
                                            <div class="flex items-center">
                                                <mat-icon class="icon-size-5 text-hint"
                                                    [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                                                <div class="ml-1.5 text-md text-secondary">{{scheduleItem.Date__c}} at
                                                    {{scheduleItem.startTime}}</div>
                                            </div>
                                            <div class="flex items-center">
                                                <mat-icon class="icon-size-5 text-hint"
                                                    [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>

                                                <div class="ml-1.5 text-md text-secondary">
                                                    {{scheduleItem.Program_Location__c}}</div>
                                            </div>
                                            <div class="flex items-center">
                                                <mat-icon class="icon-size-5 text-hint"
                                                    [svgIcon]="'heroicons_solid:user'"></mat-icon>
                                                <div class="ml-1.5 text-md text-secondary">{{scheduleItem.kid}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <button mat-icon-button *ngIf="scheduleItem.Notes__c"
                                        (click)="openDialog(scheduleItem)">
                                        <mat-icon [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </mat-panel-description>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>