
<div *ngIf="isWaitingClassSchedule" class="text-right grid-button">
    <button class="px-8 mr-2 mat-primary" mat-flat-button [color]="'accent'" *ngIf="waitButtonToggle" type="button" (click)="showWaitlistProgramConfirmation()"> Show Waitlisted Programs </button>
    <button class="px-8 mr-2 mat-primary" mat-flat-button [color]="'accent'" *ngIf="!waitButtonToggle" type="button" (click)="filterWaitingListProgram()"> Hide Waitlisted Programs </button>
</div>
<div class="flex flex-col gt-sm:flex-row desktop-table">
    <div class="flex flex-col">
        <div class="mt-5">
            <ngx-datatable [rows]="rows" style="width: 100%" class="material" rowHeight="auto" [limit]="10" [selectAllRowsOnPage]="false" columnMode="force"
                (select)="classScheduleService.onSelectClassSchedule($event)" [headerHeight]="50" [footerHeight]="50" [selectionType]="'checkbox'"
                [rowClass]="classScheduleService.getClassScheduleStatus" [selected]="selectClassScheduleList" (select)="onSelect($event)"  [(selected)]="selectClassScheduleList">
            <ngx-datatable-column [sortable]="false" width="100" [draggable]="false" [resizeable]="false" [headerCheckboxable]="!onFullyBooked || onWaitingList" [checkboxable]="true"
                >
            </ngx-datatable-column>

                <ngx-datatable-column name="Name" [sortable]="false" width="100">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.Name }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Status" [sortable]="false" prop="Is_Active__c" width="100">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span *ngIf="value === false">Unavailable</span>
                        <span *ngIf="
                                value === true &&
                                row.Available_Spaces__c > row.Booked_Count__c
                            ">Available</span>
                        <span matTooltip="This Program Has A Waitlist." *ngIf="isClassOnWaitingList(value, row)">
                            Waitlist
                        </span>
                        <span matTooltip="This Class Has Been Fully Booked" *ngIf="isClassFullyBooked(row)">Fully Booked</span>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Fee" [sortable]="false" width="100" class="tester">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span *ngIf="!isKidSubsidize">{{
                            row.Program_Fee__c | currency : "USD"
                            }}</span>
                        <span *ngIf="isKidSubsidize">
                            {{
                            row.Subsidized_Rate__c | currency : "USD"
                            }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Open Spot" [sortable]="false" width="100">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.Available_Spaces__c - row.Booked_Count__c}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Date" prop="Date__c" [sortable]="false" width="100">
                </ngx-datatable-column>

                <ngx-datatable-column name="Day" prop="Program_day__c" [sortable]="false" width="100">
                </ngx-datatable-column>
                <ngx-datatable-column name="Start Time" [sortable]="false" width="100">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.startTime }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="End Time" [sortable]="false" width="100">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.endTime }}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>
