/* eslint-disable */

const item = localStorage.getItem('user');
let local = null;
if (item) {
    local = JSON.parse(localStorage.getItem('user'));
}
export const user = {
    id: local == null ? '' : local.Uid,
    name: local == null ? '' : local.displayName,
    email: local == null ? '' : local.email,
    avatar: local == null ? '' : local.photoURL,
    status: 'online',
    uid: local == null ? '' : local.Uid,
    displayName: local == null ? '' : local.displayName,
    photoURL: local == null ? '' : local.photoURL,
    emailVerified: local == null ? '' : local.emailVerified,
};
