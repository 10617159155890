import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { FetchData, UserAccountMessages } from 'app/shared/generalMethods';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { ParentPortalService } from './services/parentPortal.service';
import { ProgramWaiverService } from './services/program-waiver.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import adaptivePlugin from '@fullcalendar/adaptive';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { MatTableModule } from '@angular/material/table';
import { PolicyWaiverComponent } from './modules/admin/programs/policy-waiver/policy-waiver.component';
import { LegendsComponent } from './modules/admin/programs/legends/legends.component';
import { InputValidation } from './shared/inputValidation';
import { InstructionGuideComponent } from './modules/admin/dashboard/instruction-guide/instruction-guide.component';
import { UserAccountMessagesComponent } from './modules/admin/dashboard/user-account-messages/user-account-messages.component';
import { ClassScheduleUpdatesComponent } from './modules/admin/dashboard/class-schedule-updates/class-schedule-updates.component';
import { ClassScheduleComponent } from './modules/admin/programs/class-schedule/class-schedule.component';
import { ClassScheduleCompactViewComponent } from './modules/admin/programs/class-schedule-compact-view/class-schedule-compact-view.component';
import { ClassScheduleGridViewComponent } from './modules/admin/programs/class-schedule-grid-view/class-schedule-grid-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaintenanceComponent } from './modules/auth/maintenance/maintenance.component';
import { ModalForCommunitySelectionComponent } from './modules/admin/modal/modal-for-community-selection/modal-for-community-selection.component';
import { ModalShowTimeExceedanceComponent } from './modules/admin/modal/modal-show-time-exceedance/modal-show-time-exceedance.component';
import { HistoryComponent } from './modules/admin/dashboard/history/history.component';
import { ModalForUrbanRuralFlagComponent } from './modules/admin/modal/modal-for-urban-rural-flag/modal-for-urban-rural-flag.component';
import { ModalUpdateKidsGradeComponent } from './modules/admin/modal/modal-update-kids-grade/modal-update-kids-grade.component';


FullCalendarModule.registerPlugins([
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    resourceTimeGridPlugin,
    adaptivePlugin,
    listPlugin,
    resourceTimelinePlugin,
]);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        MatTableModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        FullCalendarModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        //Firebase
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,

        MatDialogModule
    ],

    // eslint-disable-next-line max-len
    providers: [
        ParentPortalService,
        UserAccountMessages,
        FetchData,
        InputValidation,
        PolicyWaiverComponent,
        LegendsComponent,
        InstructionGuideComponent,
        UserAccountMessagesComponent,
        ClassScheduleUpdatesComponent,
        ClassScheduleComponent,
        ClassScheduleCompactViewComponent,
        ClassScheduleGridViewComponent,
        MaintenanceComponent,
        ModalForCommunitySelectionComponent,
        ModalShowTimeExceedanceComponent,
        HistoryComponent,
        ModalForUrbanRuralFlagComponent,
        ModalUpdateKidsGradeComponent
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
