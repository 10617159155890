export class InputValidation {
    constructor() {}

    onInputAlphabet(input: HTMLInputElement): void {
        input.addEventListener('keydown', (event) => {
            const cursorPosition = input.selectionStart;
            const value = input.value;

            if (event.key === ' ' && cursorPosition === 0) {
                event.preventDefault();
            }

            if (event.key === ' ' && cursorPosition === value.length) {
                input.value = value.trim() + ' ';
                event.preventDefault();
            }
        });

        input.addEventListener('input', () => {
            input.value = input.value.replace(/[^a-zA-Z\s]/g, '');
        });
    }

    onInputNumeric(input: HTMLInputElement): void {
        input.addEventListener('keydown', (event) => {
            if (
                !/\d|\s/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'ArrowRight' &&
                event.key !== 'ArrowLeft' &&
                event.key !== 'Tab'
            ) {
                event.preventDefault();
            }
        });
        input.value = input.value.replace(/[^0-9\s]/g, '');
    }

    onInputAlphaNumeric(input: HTMLInputElement): void {
        input.addEventListener('keydown', (event) => {
            if (!/[a-zA-Z0-9\s]/.test(event.key)) {
                event.preventDefault();
            }
        });
        input.value = input.value.replace(/[^a-zA-Z0-9\s]/g, '');
    }
}
