/* eslint-disable @angular-eslint/component-class-suffix */
// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FetchData as utility } from 'app/shared/generalMethods';


@Component({
    selector: 'show-waitlist-program-modal',
    templateUrl: './show-waitlist-program-modal.component.html',
    styleUrls: ['./show-waitlist-program-modal.component.scss'],
})
export class ShowWaitlistProgramModal {
    recordId: any;
    text: any;
    notice: any;

    constructor(
        public dialogRef: MatDialogRef<ShowWaitlistProgramModal>,
        private router: Router,
        private _utility: utility,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.text = data.record;
        this.notice =
            this._utility.data.lstAppSettings[0].Show_Waitlist_Program_Notice__c;
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onSelect(): void {
        this.dialogRef.close(true);
    }
}
