import { Component, OnInit } from '@angular/core';
import { FetchData, UserAccountMessages } from 'app/shared/generalMethods';

@Component({
  selector: 'app-user-account-messages',
  templateUrl: './user-account-messages.component.html',
  styleUrls: ['./user-account-messages.component.scss']
})
export class UserAccountMessagesComponent implements OnInit {

  constructor(
    private _userMessage: UserAccountMessages,
    private _fetchData: FetchData,
    ) { }

  ngOnInit() {
    this.getAccountStatusMessage();
  }
  getAccountStatusMessage(): any {
    const messageResponse = this._userMessage.getAccountStatusMessage(this._fetchData);
    return messageResponse;
  }

  isShowProgramRegistration(): boolean {
    const registerInProgram = this._userMessage.isShowProgramRegistration(this._fetchData);
    return registerInProgram;
  }

  isShowAddNewMembership(): boolean {
      const newMemberShip = this._userMessage.isShowAddNewMembership(this._fetchData);
      return newMemberShip;
  }

  isShowAddMembership(): boolean {
    const addMemberShip = this._userMessage.isShowAddMembership(this._fetchData);
    return addMemberShip;
  }

}