<h1 mat-dialog-title class="text-center text-3xl mb-3">NOTES</h1>

    <div mat-dialog-content>

        <div class="sm sm:flex">
                <div class="">
                        <div [innerHTML]="data.record.Notes__c"></div>
                </div>
        </div>

   </div>

<div class="text-center mt-8">
    <button mat-button class="flex-auto close-modal-button" type="button" mat-stroked-button (click)="onClose()">Close</button>

</div>

