/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { ProgramRegistrationService } from './program-registration.service';
import moment from 'moment';
import { Input, EventEmitter, Output } from '@angular/core';
import { isEmpty } from 'lodash';
import { ModalWaitingProgramWarningComponent } from 'app/modules/admin/modal/modal-waiting-program-warning/modal-waiting-program-warning.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class ClassScheduleService {
    @Input() selectedProgramId: string;
    @Input() selectedKid: [];
    @Input() kidName: string;
    @Output() setWaitingMessageCheck: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() setClassScheduleListForProgramRegistration: EventEmitter<any[]> =
        new EventEmitter<any[]>();

    @Output() isWaitingTrueChanged: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() isClassScheduleOnWaitingList: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() isClassScheduleOnFullyBooked: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    isWaitingTrue: boolean;
    selectedClassSchedule: any;
    rows: any[];
    onFullyBooked: boolean;
    onWaitingList: boolean;
    showLegend: boolean;
    selectClassScheduleList = [];
    noClassSchedule: boolean;
    programId: any;
    programsList: any;
    waitingListMessage: string;
    isKidSubsidize: boolean;
    selected: any[];
    listOfSelectedRecords: any[];

    constructor(
        private _programRegistrationService: ProgramRegistrationService,
        private dialog: MatDialog,

    ) {
        this.listOfSelectedRecords = [];
    }

    getProgramId(programId): void {
        this.fetchClassScheduleList(programId);
    }

    getKidName(kidName): void {
        if (!isEmpty(kidName)) {
            this.removeRegisteredClassScheduleFromRowsList(kidName);
        }
    }

    fetchClassScheduleList(selectedProgramId: string): void {
        const classSchedule =
            this._programRegistrationService.fetchClassScheduleByProgramID(
                selectedProgramId
            );
        this.getAllClassByProgramId(selectedProgramId);
    }

    removeRegisteredClassScheduleFromRowsList(kidName): void {

        this._programRegistrationService
            .fetchListRegistrationSchedules()
            .forEach((registrationSchedule) => {
                this.rows.forEach((classSchedule, index) => {
                    if (
                        registrationSchedule.Class_Schedule__c ===
                            classSchedule.Id &&
                        registrationSchedule.Kid_name__c === kidName
                    ) {
                        this.rows.splice(index, 1);
                        this.rows = [...this.rows];
                    } else {
                        this.rows = [...this.rows];
                    }
                });
            });
        this.checkClassAvailableSpaces(this.rows);
    }

    getAllClassByProgramId(selectedProgramId: string): void {

        this.rows = [];
        this._programRegistrationService
            .fetchClassScheduleByProgramID(selectedProgramId)
            .forEach((element) => {
                if (element.Program__c === selectedProgramId) {
                    const sdate = element.Date__c + 'T' + element.Start_Time__c;
                    const edate = element.Date__c + 'T' + element.End_Time__c;
                    element.startTime = moment(sdate).isValid
                        ? moment(sdate).utc().format('h:mm A')
                        : element.Start_Time__c;
                    element.endTime = moment(edate).isValid
                        ? moment(edate).utc().format('h:mm A')
                        : element.End_Time__c;
                    this.rows.push(element);
                    this.rows = [...this.rows];
                }
            });
        this.showLegend = this.rows.length > 0 ? true : false;
        this.checkClassAvailableSpaces(this.rows);
    }

    getClassScheduleFromClassScheduleService(): any[] {
        return this.rows;
    }

    checkClassAvailableSpaces(obj): any {
        this.onFullyBooked = false;
        this.onWaitingList = false;
        obj.forEach((element) => {
            if (
                element.Available_Spaces__c === element.Booked_Count__c &&
                element.Available_Waiting_Spaces__c > element.Booked_Waiting__c
            ) {
                this.onWaitingList = true;
                return true;
            }
            if (
                element.Available_Spaces__c === element.Booked_Count__c &&
                element.Available_Waiting_Spaces__c ===
                    element.Booked_Waiting__c
            ) {
                this.onFullyBooked = true;
                return true;
            } else {
                return false;
            }
        });
        this.setLegendForOnWaitingClass(this.onWaitingList);
        this.setLegendForOnFullyBookedClass(this.onFullyBooked);
    }

    setLegendForOnWaitingClass(onWaitingList): any {
        this.isClassScheduleOnWaitingList.emit(onWaitingList);
    }

    setLegendForOnFullyBookedClass(onFullyBooked): any {
        this.isClassScheduleOnFullyBooked.emit(onFullyBooked);
    }

    isUnavailable(row: any): boolean {
        return !row.Is_Active__c;
    }

    isAvailable(row: any): boolean {
        return (
            row.Is_Active__c && row.Available_Spaces__c > row.Booked_Count__c
        );
    }

    isWaitlist(row: any): boolean {
        return (
            row.Is_Active__c &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c > row.Booked_Waiting__c
        );
    }

    isAllBooked(row: any): boolean {
        return (
            row.Is_Active__c &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c === row.Booked_Waiting__c
        );
    }

    getClassScheduleStatus(row): any {
        if (row.Is_Active__c === false) {
            return 'row-disable';
        }
        if (
            row.Is_Active__c === true &&
            row.Available_Spaces__c > row.Booked_Count__c
        ) {
            return 'row-enable';
        }
        if (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c > row.Booked_Waiting__c
        ) {
            return 'row-enable row-waiting';
        }
        if (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c === row.Booked_Waiting__c
        ) {
            return 'row-disable';
        }
    }

    isClassOnWaitingList(value: boolean, row: any): boolean {
        return (
            value === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c > row.Booked_Waiting__c
        );
    }

    isClassFullyBooked(row: any): boolean {
        return (
            row.Is_Active__c === true &&
            row.Available_Spaces__c === row.Booked_Count__c &&
            row.Available_Waiting_Spaces__c === row.Booked_Waiting__c
        );
    }

    fetchWaitingListMessage(): any {
        this.waitingListMessage =
            this._programRegistrationService.fetchMessageListFromAppSettings();
        return this.waitingListMessage;
    }


    // async onSelectClassSchedule({ selected }): Promise<any> {
    onSelectClassSchedule({ selected }): any {
        let isWaitingCount = 0;

        selected.forEach((select) => {

            if (
                select.Is_Active__c === true &&
                select.Available_Spaces__c === select.Booked_Count__c &&
                select.Available_Waiting_Spaces__c > select.Booked_Waiting__c )
                {
                        isWaitingCount++;
                }
        });

        if (isWaitingCount > 0) {
            this.isWaitingTrue = true;
        } else {
            this.isWaitingTrue = false;
        }

        this.selectClassScheduleList.splice(0, this.selectClassScheduleList.length);
        this.selectClassScheduleList.push(...selected);
        this.setSelectClassScheduleListForProgramRegistration(this.selectClassScheduleList);
        this.setWaitingMessageCheckOnSelectClassSchedule(this.isWaitingTrue);
    }


    setWaitingMessageCheckOnSelectClassSchedule(isWaitingTrue): any {
        this.isWaitingTrueChanged.emit(isWaitingTrue);
    }

    setSelectClassScheduleListForProgramRegistration(selected): any {
        this.setClassScheduleListForProgramRegistration.emit(selected);
    }
}
