<ng-container>
  <mat-accordion class="history-accordion">
    <mat-expansion-panel class="history-dashboard" (opened)="onPanelOpened()" >
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-heading">History</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-panel-description class="panel-description">
        <div class="history-section" #historyTable>
          <!-- Existing Filters and Buttons -->
          <div class="history-heading">
            <h2>Activity Report</h2>
          </div>
          <div class="history-content">
            <!-- Filter Form -->
             <div class="select-items">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-select placeholder="Select Child" [(value)]="selectedKid" (selectionChange)="applyFilters()">
                    <mat-option value="all">All</mat-option>
                    <mat-option *ngFor="let kid of kids" [value]="kid.Id">
                      {{ kid.Name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="fill" class="form-field ">
                  <mat-select placeholder="Select Status" [(value)]="selectedStatus" (selectionChange)="applyFilters()">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="Present">Present</mat-option>
                    <mat-option value="Absent">Absent</mat-option>
                    <mat-option value="Cancelled">Cancelled</mat-option>
                  </mat-select>
                </mat-form-field>
             </div>
            
            <div [formGroup]="historyForm" class="date-range">
              <mat-form-field appearance="fill" class="form-field">
                <input matInput [matDatepicker]="fromPicker" formControlName="fromDate" (dateChange)="applyFilters()" placeholder="Choose from"/>
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="fill" class="form-field">
                <input matInput [matDatepicker]="toPicker" formControlName="toDate" (dateChange)="applyFilters()" placeholder="Choose to"/>
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
              </mat-form-field>
              <div class="reset-button">
                <button mat-button color="warn" class="reset-button" (click)="resetFilters()" [matTooltip]="'Clear Filter'">
                  <mat-icon>restart_alt</mat-icon>
                  Clear Filter
                </button>
              </div>  
              
            </div>
          </div>
          <div class="export-button">
            <button class="ml-3 mb-2" mat-flat-button color="primary" (click)="generatePDF()">Export</button>
          </div>

          <!-- Table for Desktop -->
          <div class="history-table desktop-view">
            <table mat-table [dataSource]="filteredScheduleData" class="mat-elevation-z8 activityTable">
              <!-- Columns -->
              <ng-container matColumnDef="programDescription">
                <th mat-header-cell *matHeaderCellDef>Program Description</th>
                <td mat-cell *matCellDef="let element">{{ element.programDescription }}</td>
              </ng-container>
              <ng-container matColumnDef="kidsName">
                <th mat-header-cell *matHeaderCellDef>Kid</th>
                <td mat-cell *matCellDef="let element">{{ element.kidsName }}</td>
              </ng-container>
              <ng-container matColumnDef="attendanceStatus">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{ element.attendanceStatus }}</td>
              </ng-container>
              <ng-container matColumnDef="programFee">
                <th mat-header-cell *matHeaderCellDef>Fee</th>
                <td mat-cell *matCellDef="let element">{{ element.programFee | currency: 'USD' || $0 }}</td>
              </ng-container>
              <ng-container matColumnDef="totalDue">
                <th mat-header-cell *matHeaderCellDef>Total Due</th>
                <td mat-cell *matCellDef="let element">{{ element.totalDue | currency: 'USD' }}</td>
              </ng-container>
              <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef>Notes</th>
                <td style="width: 35%;" mat-cell *matCellDef="let element">{{ element.notes}}</td>
              </ng-container>

              <!-- Header and Data Rows -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>

          <!-- Accordion for Mobile -->
          <div class="mobile-view">
            <mat-accordion>
              <mat-expansion-panel  *ngFor="let element of filteredScheduleData">
                <mat-expansion-panel-header>
                  <mat-panel-title class="program-name">{{ element.programDescription }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="program-info">
                  <p><strong>Kid:</strong> {{ element.kidsName }}</p>
                  <p><strong>Status:</strong> {{ element.attendanceStatus }}</p>
                  <p><strong>Fee:</strong> {{ element.programFee | currency: 'USD' }}</p>
                  <p><strong>Total Due:</strong> {{ element.totalDue | currency: 'USD' }}</p>
                  <p><strong>Notes:</strong> {{ element.notes }}</p>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
