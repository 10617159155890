<div class="container">
  <div class="modalHeader">
    <h2 mat-dialog-title>Payment Acknowledgment</h2>
    <p class="modalDescription">Please review and acknowledge your outstanding balance.</p>
  </div>
  <div class="action">
    <p class="due">{{ message }}</p>
    <h2 class="amount">{{ data.totalDue | currency : "USD" }}</h2>
    <form [formGroup]="acknowledgeForm">
      <div class="checkBox">
        <mat-checkbox 
          [color]="'primary'" 
          [formControlName]="'acknowledged'" 
          class="custom-checkbox" 
          (click)="closeDialog()">
          Acknowledged
        </mat-checkbox>
      </div>
    </form>
  </div>
</div>
