import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-for-urban-rural-flag',
  templateUrl: './modal-for-urban-rural-flag.component.html',
  styleUrls: ['./modal-for-urban-rural-flag.component.scss'],
})
export class ModalForUrbanRuralFlagComponent {
  selectedOption: string | null = null;
  isRural: boolean = false;

  constructor(private dialogRef: MatDialogRef<ModalForUrbanRuralFlagComponent>) {}

  confirmSelection(): void {
    if(this.selectedOption === 'isRural') {
      this.isRural = true;
    } else if(this.selectedOption === 'urban'){
      this.isRural = false;
    }
    this.dialogRef.close(this.selectedOption);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
