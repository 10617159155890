import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ParentPortalService } from 'app/services/parentPortal.service';
import { FetchData } from 'app/shared/generalMethods';
import { ModalDashboardComponent } from '../../modal/modal-dashboard/modal-dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
interface Article {
  kid: string;
  Date__c: string;
  date: Date;
}

@Component({
  selector: 'app-class-schedule-updates',
  templateUrl: './class-schedule-updates.component.html',
  styleUrls: ['./class-schedule-updates.component.scss'],
})
export class ClassScheduleUpdatesComponent implements OnInit {
  userRecord = null;
  data: any;
  showToday = true;
  dashboardMessage = '';
  classScheduleId = [];
  registrationSchedule = [];
  registrationScheduleComingSoon = [];
  registrationScheduleComingSoon2 = [];
  showTodayToggle: boolean = false;
  showTomorrowToggle: boolean = false;
  showTodayUpcomingToggles: boolean = false;
  hasValidExternalAccount: boolean = false;


  constructor(
    private _fetchData: FetchData,
    private _parentPortalService: ParentPortalService,
    public dialog: MatDialog,
  ) {
    this.userRecord = this._fetchData;
    this.classScheduleId = [];
    this.registrationSchedule = [];
    this.registrationScheduleComingSoon = [];
    this.registrationScheduleComingSoon2 = [];
  }
  @ViewChild(MatAccordion) accordion: MatAccordion;

  ngOnInit(): void {
    if (this.checkAccountValidity) {
      this.populateRegistrationSchedules();
    }
    this.setButtonToggleGroups();
  this. hasValidatorExternalAccount();
  }

  hasValidatorExternalAccount(): void {
    if (this.userRecord.hasValidExternalAccount !== false && !this.userRecord.accountBlocked && this.userRecord.data?.countProgramRegistrations > 0) {
      this.hasValidExternalAccount = true;
    }
  }

  setButtonToggleGroups(): void {
    if (this.classScheduleId.length > 0 && this.registrationScheduleComingSoon.length === 0) {
      this.showTodayToggle = true;
    } else if (this.classScheduleId.length === 0 && this.registrationScheduleComingSoon.length > 0) {
      this.showTomorrowToggle = true;
    } else if (this.classScheduleId.length > 0 && this.registrationScheduleComingSoon.length > 0) {
      this.showTodayUpcomingToggles = true;
    }
  }

  checkAccountValidity(): boolean {
    return (
      this.userRecord.hasValidExternalAccount &&
      !this.userRecord.accountBlocked
    );
  }

  todayData = (): void => {
    this.showToday = true;
  };

  tomorrowData = (): void => {
    this.showToday = false;
  };

  populateRegistrationSchedules(): void {
    if (this.userRecord && this.userRecord.data && this.userRecord.data.lstProgramRegistrations) {

      const programRegistrations = this.userRecord.data.lstProgramRegistrations;
      const registrationSchedules = this.userRecord.data.lstRegistrationSchedules;
      const classSchedules = this.userRecord.data.lstClassSchedules;

      programRegistrations.forEach((programRegistration) => {
        registrationSchedules.forEach((registrationSchedule) => {
          if (
              programRegistration.Id ===
              registrationSchedule.Program_Registration__c &&
              registrationSchedule.Is_Waiting__c === false
            ) {
            for (const item of classSchedules) {
              if (
                item.Id === registrationSchedule.Class_Schedule__c
              ) {
                this.processClassSchedule(
                  registrationSchedule,
                  item
                );
              }
            }
          }
        });
      });

      if (this.classScheduleId.length === 0) {
        this.showToday =
          this.registrationScheduleComingSoon.length > 0 ? false : true;
      }
    }
  }

  processClassSchedule(registrationSchedule: any, csItem: any): void {
    const sDate = csItem.Date__c + 'T' + csItem.Start_Time__c;
    const csDates = moment(sDate).isValid()
      ? moment(sDate).utc().format('h:mm A')
      : csItem.Start_Time__c;
    const kidName = registrationSchedule.Kid_name__c;

    if (csItem.Date__c === moment().format('YYYY-MM-DD')) {
      this.addClassScheduleToday(csItem, csDates, kidName);
    } else {
      this.addRegistrationScheduleComingSoon(csItem, kidName);
    }
  }

  addClassScheduleToday(csItem: any, csDates: any, kidName: any): void {
    const objDashboardToday = {
      Date__c: csItem.Date__c,
      startTime: csDates,
      Name: csItem.Name,
      Program_Location__c: csItem.Program_Location__c,
      kid: kidName,
      Notes__c: csItem.Notes__c,
    };
    this.classScheduleId.push(objDashboardToday);
    this.classScheduleId = this.sortByDateName(this.classScheduleId);
  }

  addRegistrationScheduleComingSoon(csItem: any, kidName: any): void {
    const sDate = csItem.Date__c + 'T' + csItem.Start_Time__c;
    const csStartTime = moment(sDate).isValid()
      ? moment(sDate).utc().format('h:mm A')
      : csItem.Start_Time__c;

    const objDashboardUpComing = {
      Date__c: csItem.Date__c,
      startTime: csStartTime,
      Name: csItem.Name,
      Program_Location__c: csItem.Program_Location__c,
      kid: kidName,
      Notes__c: csItem.Notes__c,
      date: new Date(csItem.Date__c),
    };
    this.registrationScheduleComingSoon.push(objDashboardUpComing);
    this.registrationScheduleComingSoon = this.sortByDateName(
      this.registrationScheduleComingSoon
    );
  }

  sortByDateName(articles: Article[]): Article[] {
    const parsedArticles = articles.map((article) => ({
      ...article,
      date: new Date(article.Date__c),
    }));
    const sortedArticles = parsedArticles.sort(
      (a, b) =>
        a.date.getTime() - b.date.getTime() ||
        a.kid.localeCompare(b.kid)
    );
    return sortedArticles;
  }

  openDialog = (e): void => {
    this.dialog.open(ModalDashboardComponent, {
      height: 'auto',
      width: 'auto',
      maxWidth: '750px',
      data: {
        record: e,
      },
    });
  };

}
