/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { directorSignature, FetchData } from 'app/shared/generalMethods';
import jsPDF from 'jspdf';
import { imgHeaderData, youthCenterImg } from 'app/shared/generalMethods';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetSalesforceDataService } from 'app/services/salesforce-data';

interface RegistrationSchedule {
    totalDue: number;
    programFee: number;
    programDescription: string;
    kidsName: string;
    notes: string;
    attendanceStatus: string;
    feesPaid: number;
    kidsId: string;
    createdDate: string;
    classSchedule: string;
    programDate: string;
    isCancelled: string;
}

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    providers: [DatePipe],
})
export class HistoryComponent implements OnInit, AfterViewInit {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild('historyTable') historyTable!: ElementRef;

    historyForm: FormGroup;
    displayedColumns: string[] = [
        'programDescription',
        'kidsName',
        'attendanceStatus',
        'programFee',
        'totalDue',
        'notes'
    ];
    scheduleData: RegistrationSchedule[] = [];
    filteredScheduleData: RegistrationSchedule[] = [];
    parentId: string;
    kids: any[] = [];
    classScheduleMap: Record<string, string> = {};
    selectedKid: string = 'all';
    selectedStatus: string = 'all';
    pdfObj: jsPDF = new jsPDF();
    kidsId: any[] = [];
    kidsName: any[] = [];
    physicalAddress: string = '';
    postalCode: string = '';
    townCity: string = '';
    parentName: string = '';
    paidByParent: string = '';
    paidBySubsidy: string = '';

    constructor(
        private fb: FormBuilder,
        private fetchData: FetchData,
        private datePipe: DatePipe,
        private _snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.initHistoryForm();
        this.parentId = this.fetchData.parentId;
        this.filterKidsByParentId();
        this.loadSchedules();
        this.loadParentData();
        this.calculatePayments();
        this.setInitialDateRange();
    }

    onPanelOpened(): void {
        this.applyFilters();
    }

    private setInitialDateRange(): void {
        let filteredData = this.scheduleData;
        const today = new Date();
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);

        const formattedToday = this.datePipe.transform(today, 'yyyy-MM-dd');
        const formattedOneWeekAgo = this.datePipe.transform(
            oneWeekAgo,
            'yyyy-MM-dd'
        );

        this.historyForm.patchValue({
            fromDate: formattedOneWeekAgo,
            toDate: formattedToday,
        });

        if (this.selectedKid !== 'all') {
            filteredData = filteredData.filter(
                schedule => schedule.kidsId === this.selectedKid
            );
        }
    }

    ngAfterViewInit(): void {
        if (!this.historyTable) {
            console.error('historyTable element reference not initialized');
        }
    }

    private initHistoryForm(): void {
        this.historyForm = this.fb.group({
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
        });
    }

    private filterKidsByParentId(): void {
        const lstKids = this.fetchData.data?.lstKids || [];
        this.kids = lstKids.filter(kid => kid.Parent__c === this.parentId);
        this.kidsId = this.kids.map(kid => kid.Id);
        this.kidsName = this.kids.map(kid => kid.Kid_name__c);
    }

    private loadParentData(): void {
        const lstParent = this.fetchData.data?.lstParents || [];
        if (lstParent.length > 0) {
            const parent = lstParent[0];
            this.physicalAddress = parent.Physical_Address__c || 'N/A';
            this.postalCode = parent.Postal_Code__c || 'N/A';
            this.townCity = parent.Town_City__c || 'N/A';
            this.parentName = parent.Name || 'N/A';
        } else {
            console.warn('No parent data found in lstParent.');
        }
    }

    private loadSchedules(): void {
        const lstRegistrationSchedules =
            this.fetchData.data?.lstRegistrationSchedules || [];
        const lstAttendance = this.fetchData.data?.lstAttendance || [];
        this.scheduleData = lstRegistrationSchedules.map(schedule => ({
            programDescription: schedule.Class_Schedule__r?.Name,
            attendanceStatus: schedule.Is_Cancelled__c ? 'Cancelled' : schedule.Status__c,
            feesPaid: schedule.Paid_Fee__c ?? 0,
            programFee: schedule.Program_Fees__c ?? 0,
            cancellationFee: schedule.Cancellation_Fees__c ?? 0,
            totalDue: schedule.Total_Due__c,
            kidsId: schedule.Kids__c,
            kidsName: schedule.Kid_name__c,
            notes: schedule.Notes__c,
            createdDate: schedule.CreatedDate,
            classSchedule: schedule.Class_Schedule__c,
            programDate: schedule.Program_Date__c,
            isCancelled: schedule.Is_Cancelled__c,
        }));
        this.processAttendance(lstAttendance, lstRegistrationSchedules);
        this.filteredScheduleData = [...this.scheduleData];
    }

    applyFilters(): void {
        const fromDate = this.historyForm.get('fromDate')?.value;
        const toDate = this.historyForm.get('toDate')?.value;

        let filteredData = this.scheduleData;

        if (this.selectedKid !== 'all') {
            filteredData = filteredData.filter(
                schedule => schedule.kidsId === this.selectedKid
            );
        }

        this.onKidSelectionChange();

        filteredData = filteredData.filter((schedule) => {
            const match =
                schedule.programDescription.match(/\d{4}-\d{2}-\d{2}/);
            if (!match) {
                return false;
            }

            const programDate = new Date(match[0]);

            const normalizedProgramDate = new Date(
                programDate.setHours(0, 0, 0, 0)
            );
            const normalizedFromDate = fromDate
                ? new Date(new Date(fromDate).setHours(0, 0, 0, 0))
                : null;
            const normalizedToDate = toDate
                ? new Date(new Date(toDate).setHours(0, 0, 0, 0))
                : null;

            if (
                normalizedFromDate &&
                normalizedFromDate > normalizedProgramDate
            ) {
                return false;
            }
            if (normalizedToDate && normalizedToDate < normalizedProgramDate) {
                return false;
            }
            return true;
        });

        if (this.selectedStatus !== 'all') {
          filteredData = filteredData.filter(
              schedule => schedule.attendanceStatus === this.selectedStatus
          );
      }

        this.filteredScheduleData = filteredData;
        if (this.filteredScheduleData.length === 0) {
            this.openSnackBar('No Data Found');
        }
    }

    resetFilters(): void {
        this.historyForm.reset();
        this.selectedKid = 'all';
        this.selectedStatus = 'all';
        this.filteredScheduleData = this.scheduleData;
    }

    onKidSelectionChange(): void {
        if (this.selectedKid === 'all') {
            this.filteredScheduleData = [...this.scheduleData];
        } else {
            this.filteredScheduleData = this.scheduleData.filter(
                schedule => schedule.kidsId === this.selectedKid
            );
        }
        if (this.filteredScheduleData.length === 0) {
            console.warn(
                `No data available for the selected kid with ID: ${this.selectedKid}`
            );
        }
    }

    getSelectedKidName(): string {
        const kid = this.kids.find(k => k.Id === this.selectedKid);
        return kid ? kid.Name : 'All Kids';
    }


    generatePDF(): void {
        this.initializePDF();
        this.createPDFPages();
        this.pdfObj.save('BGC_Wolf_Creek_History_Report.pdf');
    }

    private initializePDF(): void {
        this.pdfObj = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [250, 250],
        });
    }

    private createPDFPages(): void {
        const pdfWidth = this.pdfObj.internal.pageSize.getWidth();
        const pdfHeight = this.pdfObj.internal.pageSize.getHeight();
        let currentPage = 1;
        const firstPageRows = 15;
        const otherPageRows = 15;

        const tableData = this.getTableData();

        while (tableData.length > 0) {
            let startY = 62;

            if (currentPage > 1) {
                this.pdfObj.addPage();
            }

            this.addHeader();
            startY = this.addParentInfoTable(startY);
            const pageData = tableData.splice(0, currentPage === 1 ? firstPageRows : otherPageRows);
            this.addTableData(pageData, startY);

            this.addFooter(currentPage, pdfWidth, pdfHeight, tableData.length, otherPageRows);
            currentPage++;
        }
    }

    private addHeader(): void {
        const leftX = 10;

        let currentY = 5;
        this.pdfObj.addImage(imgHeaderData, 'PNG', leftX, currentY, 78, 17);
        this.pdfObj.addImage(youthCenterImg, 'PNG', 195, currentY, 46, 27);

        currentY += 8;
        this.setBoldFont();
        this.pdfObj.text('Box 4115, Ponoka AB T4J 1R5', 139, currentY, { align: 'center' });
        this.pdfObj.text('403-783-3112', 139, currentY + 5, { align: 'center' });
        this.pdfObj.text('office@bgcwolfcreek.com', 139, currentY + 10, { align: 'center' });
        this.setNormalFont();

        currentY += 22;
        this.pdfObj.setFontSize(16).setFont('helvetica', 'bold');
        this.pdfObj.text('Childcare Tax Receipt', leftX, currentY);
        this.pdfObj.addImage(directorSignature, 'PNG', leftX, currentY + 4, 36, 12);
        this.setNormalFont();
        this.pdfObj.setFontSize(10).text('Director of Childcare', leftX, currentY + 18);
        const currentDate = new Date().toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    });
        this.pdfObj.text(`Date Receipt Issued: ${currentDate}`, leftX, currentY + 24);
    }

    private addParentInfoTable(startY: number): number {
        autoTable(this.pdfObj, {
            theme: 'striped',
            head: [['Parent Information', '', '', '']],
            body: [
                this.createBoldRow('Parent Name:', this.parentName, 'Paid by Parent:', this.paidByParent),
                this.createBoldRow('Physical Address:', this.physicalAddress, 'Paid by Subsidy:', this.paidBySubsidy),
                this.createBoldRow('Town/City:', this.townCity),
                this.createBoldRow('Postal Code:', this.postalCode)
            ],
            startY,
            margin: { left: 10, right: 10 },
            headStyles: { fillColor: [68, 70, 84] },
            bodyStyles: { fontSize: 10, cellPadding: 2 },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            columnStyles: { 0: { cellWidth: 35 }, 2: { cellWidth: 33 } }
        });
        return startY + 40; // Move down to avoid overlapping next table
    }

    private addTableData(pageData: any[], startY: number): void {
        autoTable(this.pdfObj, {
            theme: 'striped',
            head: [['Program', 'Kid', 'Status', 'Fee', 'Total Due', 'Notes']],
            body: pageData,
            startY,
            margin: { left: 10, right: 10 },
            headStyles: { fillColor: [68, 70, 84] },
            bodyStyles: { fontSize: 10, cellPadding: 2 }
        });
    }

    private addFooter(page: number, pdfWidth: number, pdfHeight: number, remainingRows: number, rowsPerPage: number): void {
        const totalPages = Math.ceil(remainingRows / rowsPerPage) + page;
        this.pdfObj.setFontSize(10).text(`Page ${page} of ${totalPages}`, pdfWidth - 40, pdfHeight - 10);
    }

    private createBoldRow(label1: string, value1: string, label2: string = '', value2: string = ''): any[] {
        return [
            { content: label1, styles: { fontStyle: 'bold' } }, value1,
            { content: label2, styles: { fontStyle: 'bold' } }, value2
        ];
    }

    private getTableData(): any[] {
        return this.filteredScheduleData.map(row => [
            row.programDescription,
            row.kidsName,
            row.attendanceStatus,
            `$${row.programFee.toFixed(2)}`,
            `$${row.totalDue.toFixed(2)}`,
            row.notes,
        ]);
    }

    private setBoldFont(): void {
        this.pdfObj.setFont('helvetica', 'bold');
        this.pdfObj.setFontSize(10);
        this.pdfObj.setTextColor(68, 70, 84);
    }

    private setNormalFont(): void {
        this.pdfObj.setFont('helvetica', 'normal');
    }

    private processAttendance(
        lstAttendance: any[],
        classSchedules: any[]
    ): void {
        this.scheduleData.forEach((schedule) => {
            if (schedule.isCancelled) {
                schedule.attendanceStatus = 'Cancelled';
                return;
            }

            const attendance = lstAttendance.find(
                att =>
                    att.Class_Schedule__c === schedule.classSchedule &&
                    att.Program_Date__c === schedule.programDate &&
                    att.Kid__c === schedule.kidsId
            );

            if (attendance) {
                if (attendance.Is_Absent__c) {
                    schedule.attendanceStatus = 'Absent';
                } else if (attendance.In_Time__c || attendance.Out_Time__c) {
                    schedule.attendanceStatus = 'Present';
                } else {
                    schedule.attendanceStatus = 'Absent';
                }
            } else {
                schedule.attendanceStatus = 'Absent';
            }
        });

        this.filteredScheduleData = [...this.scheduleData];
    }


    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    openSnackBar(message: string) {
        this._snackBar.open(message, 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    private calculatePayments(): void {
        const lstWalletSummary = this.fetchData.data?.lstWalletSummary || [];

        const paidByParentAmount = lstWalletSummary
            .filter(entry => ['Cash', 'E-transfer', 'Stripe', 'Cheque'].includes(entry.Fund_Source__c))
            .reduce((sum, entry) => sum + (entry.Amount__c ?? 0), 0);

        const paidBySubsidyAmount = lstWalletSummary
            .filter(entry => entry.Fund_Source__c === 'Subsidy')
            .reduce((sum, entry) => sum + (entry.Amount__c ?? 0), 0);

        this.paidByParent = `$${paidByParentAmount.toFixed(2)}`;
        this.paidBySubsidy = `$${paidBySubsidyAmount.toFixed(2)}`;
    }


}
