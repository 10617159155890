import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParentPortalService } from 'app/services/parentPortal.service';
import { FetchData } from 'app/shared/generalMethods';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetSalesforceDataService } from 'app/services/salesforce-data';

@Component({
  selector: 'app-modal-update-kids-grade',
  templateUrl: './modal-update-kids-grade.component.html',
  styleUrls: ['./modal-update-kids-grade.component.scss']
})
export class ModalUpdateKidsGradeComponent implements OnInit {
  kidsForm: FormGroup;
  userRecord: any;
  isDisabledButton: boolean = false;
  parentId: any;
  apiResult: any;

  constructor(
    private _fetchData: FetchData,
    private _parentPortalService: ParentPortalService,
    public dialogRef: MatDialogRef<ModalUpdateKidsGradeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _getSalesforceDataService: GetSalesforceDataService
  ) {}

  ngOnInit(): void {
    this.userRecord = this._fetchData.data;
    this.initializeForm();
  }

  initializeForm(): void {
    if (this.userRecord?.lstKids?.length > 0) {
      this.parentId = this.userRecord.lstKids[0]?.Parent__c;
      const kidsFormGroup = {};
      this.userRecord.lstKids.forEach((kid) => {
        kidsFormGroup[kid.Id] = [kid.Grade__c, Validators.required];
      });

      this.kidsForm = this._formBuilder.group(kidsFormGroup);
    } else {
      console.error('No kids data available for initialization.');
    }
  }

  setFormValues(response): void {
    this.kidsForm = this._formBuilder.group({
      grade: [
        response.Grade__c !== 'null' ? response.Grade__c : '',
        [Validators.required],
      ],
    });
  }

  onFormSubmit(): void {
    if (this.kidsForm.valid) {
      this.isDisabledButton = true;
      const updates = Object.keys(this.kidsForm.value).map(kidId => ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Id: kidId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Grade__c: this.kidsForm.value[kidId]
      }));

      this._parentPortalService.updateKidsGrade(updates, this.parentId).subscribe(
        (response) => {
          this.apiResult = this._fetchData.parseIntoJSON(response.msg);
          if (this.apiResult?.data === null) {
            this.redirectUserToMaintenancePage();
          } else if (this.apiResult.success === true) {
            this._fetchData.setLocalStorage(response.msg);
            this._snackBar.open('Record updated successfully!', 'Close', {
              duration: 3000,
              verticalPosition: 'top',
            });
            this.dialogRef.close();
          }
        },
        (error) => {
          console.error('Error updating grades:', error);
          this.isDisabledButton = false;
          this._snackBar.open('Failed to update grades', 'Close', {
            duration: 3000,
            verticalPosition: 'top',
          });
        }
      );
    } else {
      console.error('Form is invalid:', this.kidsForm);
    }
  }

  redirectUserToMaintenancePage(): void {
    this._getSalesforceDataService.redirectUserToMaintenancePage();
  }
}

