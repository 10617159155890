import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FetchData } from 'app/shared/generalMethods';

@Component({
  selector: 'app-modal-show-time-exceedance',
  templateUrl: './modal-show-time-exceedance.component.html',
  styleUrls: ['./modal-show-time-exceedance.component.scss']
})
export class ModalShowTimeExceedanceComponent implements OnInit {
  attentionMessage = '';
  constructor(
    public dialogRef: MatDialogRef<ModalShowTimeExceedanceComponent>,
    private _fetchData: FetchData,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {

   }

  ngOnInit(): void {
      this.getParentAttentionMessage();
    }

  onNoClick(): void {
      this.dialogRef.close({
          food: 'No',
      });
  }

  cancel(): void {
      this.dialogRef.close({
          food: 'cancel',
      });
  }

  onSelect(): void {
    this.dialogRef.close(true);
  }

  getParentAttentionMessage(): void {
      this.attentionMessage = this._fetchData.data.lstAppSettings[0].Parent_s_Attention_Message__c;
  }

}


