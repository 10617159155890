<ng-container>
    <mat-accordion class="instruction-guide-accordion" classmulti>
        <mat-expansion-panel class="instruction-dashboard ">
            <mat-expansion-panel-header>
                <mat-panel-title class="accordion-heading">
                    FAQ
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
                <div>
                    <div id="welcome" [innerHTML]="dashboardInstructionGuide"></div>
                </div>
            </mat-panel-description>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
