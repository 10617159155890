import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'public-schedule',
                loadChildren: () =>
                    import(
                        'app/modules/auth/public-calender/public-calender.module'
                    ).then((m) => m.PublicCalenderModule),
            },
            {
                path: 'terms-of-service',
                loadChildren: () =>
                    import(
                        'app/modules/auth/terms-of-service/terms-of-service.module'
                    ).then((m) => m.TermsOfServiceModule),
            },
            {
                path: 'maintenance',
                loadChildren: () =>
                 import(
                   'app/modules/auth/maintenance/maintenance.module'
                 ).then((m) => m.MaintenanceModule),
           },
            {
                path: 'privacy-policy',
                loadChildren: () =>
                    import(
                        'app/modules/auth/privacy-policy/privacy-policy.module'
                    ).then((m) => m.PrivacyPolicyModule),
            },
            // {path: 'contact-us', loadChildren: () => import('app/modules/auth/contact-us/contact-us.module').then(m => m.ContactUsModule)},
            {
                path: 'contactus',
                loadChildren: () =>
                    import(
                        'app/modules/auth/contact-us2/contact-us2.module'
                    ).then((m) => m.ContactUs2Module),
            },
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('app/modules/admin/dashboard/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'membership',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/membership/membership.module'
                    ).then((m) => m.MemberShipModule),
            },
            {
                path: 'registered-programs',
                loadChildren: () =>
                    import('app/modules/admin/programs/registered-programs/registered-programs.module').then(
                        (m) => m.RegisteredProgramsModule
                    ),
            },
            {
                path: 'modal',
                loadChildren: () =>
                    import('app/modules/admin/modal/modal/modal.module').then(
                        (m) => m.ModalModule
                    ),
            },
            {
                path: 'parent-profile',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/parent-profile/parent-profile.module'
                    ).then((m) => m.ParentProfileModule),
            },
            {
                path: 'add-children',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/add-children/add-children.module'
                    ).then((m) => m.AddChildrenModule),
            },
            {
                path: 'children-detail',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/children-detail/children-detail.module'
                    ).then((m) => m.ChildrenDetailModule),
            },
            {
                path: 'add-healthInformation',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/add-healthInformation/add-healthInformation.module'
                    ).then((m) => m.AddHealthInformationModule),
            },
            {
                path: 'all-membership',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/all-membership/all-membership.module'
                    ).then((m) => m.AllMembershipsModule),
            },
            {
                path: 'membership-detail',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/membership-detail/membership-detail.module'
                    ).then((m) => m.MembershipDetailModule),
            },
            {
                path: 'registration-detail',
                loadChildren: () =>
                    import(
                        'app/modules/admin/programs/registration-detail/registration-detail.module'
                    ).then((m) => m.RegistrationDetailModule),
            },
            {
                path: 'program-registration',
                loadChildren: () =>
                    import(
                        'app/modules/admin/programs/program-registration/program-registration.module'
                    ).then((m) => m.ProgramRegistrationModule),
            },
            {
                path: 'add-emergencycontact',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/add-emergencycontact/add-emergencycontact.module'
                    ).then((m) => m.AddEmergencyContactModule),
            },
            {
                path: 'emergencycontact-detail',
                loadChildren: () =>
                    import(
                        'app/modules/admin/membership/emergencycontact-detail/emergencycontact-detail.module'
                    ).then((m) => m.EmergencyContactDetailModule),
            },
            {
                path: 'stripe-payment',
                loadChildren: () =>
                    import(
                        'app/modules/admin/programs/stripe-payment/stripe-payment.module'
                    ).then((m) => m.StripePaymentModule),
            },
            {
                path: 'waiting-registration',
                loadChildren: () =>
                    import(
                        'app/modules/admin/programs/waiting-registration/waiting-registration.module'
                    ).then((m) => m.WaitingRegistrationModule),
            },
            // {path: 'contact-us', loadChildren: () => import('app/modules/auth/contact-us/contact-us.module').then(m => m.ContactUsModule)},
            {
                path: 'contact-us',
                loadChildren: () =>
                    import(
                        'app/modules/auth/contact-us2/contact-us2.module'
                    ).then((m) => m.ContactUs2Module),
            },
        ],
    },
];
