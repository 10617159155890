<div *ngIf="isWaitingClassSchedule"  class="text-right compact-button">
    <button class="px-8 mr-2 mat-primary" mat-flat-button [color]="'accent'" *ngIf="waitButtonToggle" type="button" (click)="showWaitlistProgramConfirmation()"> Show Waitlisted Programs</button>
    <button class="px-8 mr-2 mat-primary" mat-flat-button [color]="'accent'" *ngIf="!waitButtonToggle" type="button" (click)="filterWaitingListProgram()"> Hide Waitlisted Programs </button>
</div>
<div class="flex flex-col gt-sm:flex-row mobile-table">
    <div class="flex flex-col">
        <div class="mt-5">
            <ngx-datatable
                [rows]="rows"
                style="width: 100%"
                class="material"
                rowHeight="auto"
                [limit]="10"
                [selectAllRowsOnPage]="false"
                columnMode="force"
                (select)="classScheduleService.onSelectClassSchedule($event)"
                [headerHeight]="50"
                [footerHeight]="50"
                [selectionType]="'checkbox'"
                [rowClass]="classScheduleService.getClassScheduleStatus"
                [selected]="selectClassScheduleList"
                [(selected)]="selectClassScheduleList"
                (select)="onSelect($event)"
            >
                <ngx-datatable-column
                    class="checkbox-column"
                    [sortable]="true"
                    width="30"
                    [draggable]="false"
                    [resizeable]="false"
                    [headerCheckboxable]="false"
                    [checkboxable]="true"
                >
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="true" width="200">
                    <ng-template ngx-datatable-header-template>
                        <span class="class-schedule-heading"
                            >Select class schedules for :
                            <strong class="childname">{{
                                kidName
                            }}</strong></span
                        >
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <mat-accordion
                            [ngClass]="
                                classScheduleService.getClassScheduleStatus(row)
                            "
                            [ngClass]="{
                                unavailable: isUnavailable(row),
                                available: isAvailable(row),
                                waitlist: isWaitlist(row),
                                allbooked: isAllBooked(row)
                            }"
                        >
                            <mat-expansion-panel class="">
                                <mat-expansion-panel-header
                                    class="program-regis-mat-header"
                                >
                                    <div class="d-flex flex-column flex-md-row">
                                        <div class="mr-3 mobile-program-name">
                                            {{ row.Name }}
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <mat-expansion-panel-content
                                    class="program-registration-panel"
                                >
                                    <div>
                                        <span *ngIf="row.Is_Active__c === false"
                                            ><strong>Status: </strong
                                            >Unavailable</span
                                        >
                                        <span
                                            *ngIf="
                                                row.Is_Active__c === true &&
                                                row.Available_Spaces__c >
                                                    row.Booked_Count__c
                                            "
                                            ><strong>Status:</strong>
                                            Confirmed</span
                                        >

                                        <span
                                            matTooltip="This Program Has A Waitlist."
                                            *ngIf="isClassOnWaitingList(row)"
                                            ><strong>Status:</strong>
                                            Waitlist
                                        </span>
                                        <span
                                            matTooltip="This Class Has Been Fully Booked"
                                            *ngIf="isClassFullyBooked(row)"
                                            ><strong>Status:</strong> Fully
                                            Booked</span
                                        >
                                    </div>
                                    <div>
                                        <span *ngIf="!isKidSubsidize"
                                            ><strong>Fee: </strong
                                            >{{
                                                row.Program_Fee__c
                                                    | currency : "USD"
                                            }}</span
                                        >
                                        <span *ngIf="isKidSubsidize"
                                            ><strong>Fee: </strong>
                                            {{
                                                row.Subsidized_Rate__c
                                                    | currency : "USD"
                                            }}</span
                                        >
                                    </div>

                                    <div>
                                        <strong>Open Spot: </strong>{{ row.Available_Spaces__c - row.Booked_Count__c}}
                                    </div>
                                    <div>
                                        <strong>Date: </strong>{{ row.Date__c }}
                                    </div>
                                    <div>
                                        <strong>Day: </strong
                                        >{{ row.Program_day__c }}
                                    </div>
                                    <div>
                                        <strong>Start Time: </strong
                                        >{{ row.startTime }}
                                    </div>
                                    <div>
                                        <strong>End Time: </strong
                                        >{{ row.endTime }}
                                    </div>
                                </mat-expansion-panel-content>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>
