<h2 class="noticeHeading">Notice to Members</h2>
<form class="p-4 bg-card shadow rounded overflow-hidden" [formGroup]="profileForm" #parent.NameProfileNgForm="ngForm" (ngSubmit)="onFormSubmit()">
  <div class="Message" [innerHTML]="communityMessage"></div>
  <div class="">
    <mat-form-field class="flex-auto gt-xs:pr-3 selectCommunity">
      <mat-label>Please confirm your community</mat-label>
      <mat-select [placeholder]="'Select Community'" formControlName="community" required>
        <mat-option *ngFor="let list of userRecord.lstCommunityPicklist" [value]="list.key">{{ list.value }}</mat-option>
      </mat-select>
      <mat-error *ngIf="profileForm.get('community').invalid">Please confirm your community</mat-error>
    </mat-form-field>
  </div>
  <div class="updateButton">
    <button class="mb-3 px-8" mat-flat-button [color]="'primary'" type="submit">Confirm</button>
  </div>
</form>

