/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
    HttpClient,
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpResponse,
    HttpHeaders,
} from '@angular/common/http';
import { async, Observable } from 'rxjs';
import * as moment from 'moment';
import { elementsFromPoint } from '@swimlane/ngx-datatable';
import { FetchData } from 'app/shared/generalMethods';

@Injectable()
export class ParentPortalService {
    headers: HttpHeaders;
    options: any;
    user: any;
    serverUrl = environment.apiUrl;
    serverCache = environment.apiUrlCache;
    ipifyUrl = environment.ipifyUrl;
    ipinfoUrl = environment.ipinfoUrl;
    ipinfoToken = environment.ipinfoToken;

    parentObject: any;
    emergencyContactObject = [];
    childrenObject = [];
    membershipObject = [];
    programObject = [];
    programLocationObject = [];
    allClassScheduleObject = [];
    parenIdOrUid = '';

    constructor(
        private http: HttpClient,
        private _httpClient: HttpClient,
        private _fetchData: FetchData
    ) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.options = { headers: this.headers };
        this.parenIdOrUid = this._fetchData.parentId;
    }

    getClientIPAddressByIpify() {
        try {
            return this.http.get(this.ipifyUrl);
        } catch (error) {
            console.error('Error fetching client IP address:', error);
        }
    }

    getLocationByIp(ipAddress) {
        try {
            return this.http.get(this.ipinfoUrl + ipAddress + this.ipinfoToken);
        } catch (error) {
            console.error(
                `Error fetching location for IP ${ipAddress}:`,
                error
            );
        }
    }

    addParentGoogle(record): Observable<any> {
        const url = this.serverUrl + '/addParent';
        const body = {
            parenIdOrUid: record.uid,
            name: record.name,
            email: record.email,
            uid: record.uid,
            isActive: true,
        };
        return this._httpClient.post(url, body, this.options);
    }

    addParent(record, uid): Observable<any> {
        const url = this.serverUrl + '/addParent';
        const body = {
            parenIdOrUid: uid,
            name: record.pgiFullName,
            email: record.pgiEmail,
            uid: uid,
            isActive: true,
            postalCode: record.pgiPostalCode,
            city: record.pgiCity,
            address: record.pgiPhysicalAddress,
            homePhone: record.pgiHomePhone,
            workPhone: record.pgiWorkPhone,
            isRural: record.pgiIsRural,
            cellPhone: record.pgiCellPhone,

            nameSp: record.pgiFullNameSp,
            emailSp: record.pgiEmailSp,
            postalCodeSp: record.pgiPostalCodeSp,
            citySp: record.pgiCitySp,
            addressSp: record.pgiPhysicalAddressSp,
            homePhoneSp: record.pgiHomePhoneSp,
            workPhoneSp: record.pgiWorkPhoneSp,
            cellPhoneSp: record.pgiCellPhoneSp,
        };
        return this._httpClient.post(url, body, this.options);
    }

    updateParent(record, ids): Observable<any> {
        const url = this.serverUrl + '/updateParent';
        const body = {
            parenIdOrUid: ids,
            id: ids,
            name: record.pgiFullName,
            email: record.pgiEmail,
            city: record.pgiCity,
            postalCode: record.pgiPostalCode,
            physicalAddress: record.pgiPhysicalAddress,
            homePhone: record.pgiHomePhone,
            workPhone: record.pgiWorkPhone,
            isRural: record.pgiIsRural,
            cellPhone: record.pgiCellPhone,


            nameSp: record.pgiFullNameSp,
            emailSp: record.pgiEmailSp,
            postalCodeSp: record.pgiPostalCodeSp,
            citySp: record.pgiCitySp,
            addressSp: record.pgiPhysicalAddressSp,
            homePhoneSp: record.pgiHomePhoneSp,
            workPhoneSp: record.pgiWorkPhoneSp,
            cellPhoneSp: record.pgiCellPhoneSp,
        };
        return this._httpClient.put(url, body, this.options);
    }

    getParentByUID(id): Observable<any> {
        const url = this.serverUrl + '/parentByUID?id=' + id;
        return this._httpClient.get(url, this.options);
    }

    getParentById(id): Observable<any> {
        const url = this.serverUrl + '/parentById?id=' + id;
        return this._httpClient.get(url, this.options);
    }

    addChildren(record, parentId, ignoreValidation): Observable<any> {
        const url = this.serverUrl + '/addChildren';
        const body = {
            parenIdOrUid: parentId,
            Parent__c: parentId,
            name: record.fullName,
            Address__c: record.address,
            Child_Resides_With__c: record.childResides,
            Community_Registering_For__c: record.community,
            Date_of_Birth__c: moment(record.dob).format('YYYY-MM-DD'),
            Ethnicity__c: record.ethnicity,
            Gender__c: record.gender,
            Grade__c: record.grade,
            Guardianship_Order_If_Applicable__c: record.guardianshipOrder,
            Kid_Age__c: record.childAge,
            Parent_Marital_Status__c: record.parentMaritalStatus,
            Postal_Code__c: record.postalCode,
            School__c: record.school,
            Town_City__c: record.city,
            isSubsidies: record.isSubsidies,
            isAgreeOnPolicy: record.isAgreeOnPolicy,
            isActive: true,
            ignoreValidation: ignoreValidation,
            isConsent: record.isConsent,
            healthCard: record.healthCard,
            immunizations: record.immunizations,
            allergies: record.allergies,
            disabilityIllness: record.disabilityIllness,
            medication: record.medication,
            otherConcern: record.otherConcern,
            Parent_Name__c: record.parentSignature,
        };
        return this._httpClient.post(url, body, this.options);
    }

    updateChildren(
        id,
        credit,
        updateMemberCreditProgram,
        parentId,
        programRegistrationId
    ): Observable<any> {
        const url = this.serverUrl + '/updateChildren';
        const body = {
            parenIdOrUid: parentId,
            id: id,
            subsidizedCredit: credit,
            updateMemberCreditProgram: updateMemberCreditProgram,
            programRegistrationId: programRegistrationId,
            ignoreValidation: false,
        };
        return this._httpClient.put(url, body, this.options);
    }

    addMemberShipForm(record, parentId): Observable<any> {
        const url = this.serverUrl + '/addMembershipForm';
        const body = {
            parenIdOrUid: parentId,
            ipFullName1: record.pickupChildrenFullName1,
            ipFullName2: record.pickupChildrenFullName2,
            ipFullName3: record.pickupChildrenFullName3,
            ipPhone1: record.pickupChildrenPhone1,
            ipPhone2: record.pickupChildrenPhone2,
            ipPhone3: record.pickupChildrenPhone3,
            glsDate: moment(record.liabilityDate).format('YYYY-MM-DD'),
            glsIName: record.liabilityIName,
            glsOfName: record.liabilityOfName,
            glsParentSignature: record.liabilityParentSignature,
            policyCheckBox: true,
            parentId: parentId,
            isActive: true,
            subscription: 'Subscribe',
            ignoreValidation: true,
        };
        return this._httpClient.post(url, body, this.options);
    }

    updateMemberShipForm(record, parentId, memberId): Observable<any> {
        const url = this.serverUrl + '/updateMemberShipForm';
        const body = {
            id: memberId,
            parenIdOrUid: parentId,
            ipFullName1: record.fullName1,
            ipFullName2: record.fullName2,
            ipFullName3: record.fullName3,
            ipPhone1: record.phone1,
            ipPhone2: record.phone2,
            ipPhone3: record.phone3,
            subscription: record.subscription,
        };
        return this._httpClient.put(url, body, this.options);
    }

    updateMembershipSubscription(recId, subs, parentId): Observable<any> {
        const url = this.serverUrl + '/updateMembershipSubscription';
        const body = {
            parenIdOrUid: parentId,
            id: recId,
            subscription: subs,
            ignoreValidation: false,
        };
        return this._httpClient.put(url, body, this.options);
    }

    updateParentCommunity(record, ids): Observable<any>{
        const url = this.serverUrl + '/updateParentCommunity';
        const body = {
            parenIdOrUid: ids,
            community: record.community,
        };
        return this._httpClient.put(url, body, this.options);
    }

    updateKidsGrade(record, ids): Observable<any>{
        const url = this.serverUrl + '/updatekidsGrade';
        const body = {
            parenIdOrUid: ids,
            grade: record,
        };
        return this._httpClient.put(url, body, this.options);
    }

    updateEmergencyContact(record, parentId, id): Observable<any> {
        const url = this.serverUrl + '/updateEmergencyContact';
        const body = {
            parenIdOrUid: parentId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Id: id,
            contactName: record.contactName,
            city: record.city,
            postalCode: record.postalCode,
            physicalAddress: record.physicalAddress,
            homePhone: record.homePhone,
            cellPhone: record.cellPhone,
            workPhone: record.workPhone,
            canPickChild: record.canPickChild,
        };
        return this._httpClient.put(url, body, this.options);
    }

    addEmergencyContact(record, parent, ignoreValidation): Observable<any> {
        const url = this.serverUrl + '/addEmergencyContact';
        const body = {
            parenIdOrUid: parent,
            city: record.city,
            postalCode: record.postalCode,
            physicalAddress: record.physicalAddress,
            homePhone: record.homePhone,
            cellPhone: record.cellPhone,
            workPhone: record.workPhone,
            canPickChild: record.canPickChild,
            parentId: parent,
            contactName: record.contactName,
            isActive: true,
            ignoreValidation: ignoreValidation,
        };
        return this._httpClient.post(url, body, this.options);
    }

    addProgramRegistration(
        record,
        email,
        childSubsidizeCredit,
        totalFee,
        parentId,
        email2
    ): Observable<any> {
        const url = this.serverUrl + '/addProgramRegistration';
        const body = {
            parenIdOrUid: parentId,
            Name: record.step2.child,
            Kids__c: record.step2.child,
            Program__c: record.step1.program,
            Notes__c: '',
            Parent_Email__c: email,
            Parent_Email2__c: email2,
            Total_Fee__c: totalFee,
            Sub_Total__c: childSubsidizeCredit,
            isActive: true,
            parentSignature: record.step5.parentSignature,
        };
        return this._httpClient.post(url, body, this.options);
    }

    addRegistrationSchdule(dataArray): Observable<any> {
        const url = this.serverUrl + '/addRegistrationSchedule';
        const body = dataArray;
        return this._httpClient.post(url, body, this.options);
    }

    getProgramLocation(): Observable<any> {
        const url = this.serverUrl + '/getProgramLocation';
        return this._httpClient.get(url, this.options);
    }

    getClassSchedule(): Observable<any> {
        const url = this.serverUrl + '/getClassSchedule';
        return this._httpClient.get(url, this.options);
    }

    updateWaitingListProgram(record): Observable<any> {
        const url = this.serverUrl + '/updateWaitingListProgram';
        const body = {
            parenIdOrUid: record.parenIdOrUid,
            programRegistrationId: record.programRegistrationId,
            registrationScheduleId: record.registrationScheduleId,
            classScheduleId: record.classScheduleId,
            ignoreValidation: false,
        };

        return this._httpClient.post(url, body, this.options);
    }

    addChildHealthInformation(record, ids, parentId): Observable<any> {
        const url = this.serverUrl + '/addHealthInformation';
        const body = {
            parenIdOrUid: parentId,
            name: ids,
            allergies: record.allergies,
            anyOtherConcerns: record.anyOtherConcerns,
            disabilities: record.disability,
            healthCard: record.healthCard,
            immunizations: record.immunizations,
            kidId: ids,
            medication: record.medication,
            isActive: true,
            ignoreValidation: false,
        };
        return this._httpClient.post(url, body, this.options);
    }

    deleteProgramRegistration(bodies): Observable<any> {
        const url = this.serverUrl + '/deleteProgramRegistration';
        const body = bodies;
        return this._httpClient.post(url, body, this.options);
    }

    getDataFromCache(): Observable<any> {
        const url = this.serverCache + '/getDataFromCache';
        return this._httpClient.get(url, this.options);
    }


    updateChildANDHealth(record, parentId, id, hid): Observable<any> {

        const url = this.serverUrl + '/updateChildANDHealth';
        const body = {
            parenIdOrUid: parentId,
            id: id,
            healthId: hid,
            childName: record.childName,
            childDob: record.childDob,
            childGender: record.childGender,
            childAge: record.childAge,
            childCommunityReg: record.childCommunityReg,
            childSchool: record.childSchool,
            childReidesWith: record.childReidesWith,
            childGrade: record.childGrade,
            childMaterialStatus: record.childMaterialStatus,
            childAddress: record.childAddress,
            childCity: record.childCity,
            childpostalCode: record.childpostalCode,
            childSubsidize: record.childSubsidize,
            childConsent: record.childConsent,
            childGuardianShip: record.childGuardianShip,
            childEthnicity: record.childEthnicity,
            healthCard: record.healthCard,
            immunizations: record.immunizations,
            allergey: record.allergey,
            disabilityIllness: record.disabilityIllness,
            medication: record.medication,
            otherConcern: record.otherConcern,
        };
        return this._httpClient.put(url, body, this.options);
    }

    submitContactUsForm(record): Observable<any> {
        const url = this.serverUrl + '/submitContactUsForm';
        const body = {
            firstname: record.firstname,
            lastname: record.lastname,
            email: record.email,
            message: record.message,
        };

        return this._httpClient.post(url, body, this.options);
    }

    addAuditTrailData(record, parentId): Observable<any> {
        const url = this.serverUrl + '/addAuditTrailData';
        const body = {
            parenIdOrUid: parentId,
            auditTrailRecord: record,
            ignoreValidation: false,
        };

        return this._httpClient.post(url, body, this.options);
    }
}
