// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, NavigationExtras } from '@angular/router';
import { relativeTimeThreshold } from 'moment';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'modal-dashboard',
  templateUrl: './modal-dashboard.component.html',
  styleUrls: ['./modal-dashboard.component.scss'],
})

export class ModalDashboardComponent {

  recordId: any;


  constructor(
    public dialogRef: MatDialogRef<ModalDashboardComponent>,private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {

        }


        onClose(): void {
    this.dialogRef.close({

    });
  }

  registration(): void {
    this.onClose();
    this.router.navigate(['/sign-in']);
  }


}
